import React, { useRef, useMemo, useState, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { useGameStore } from '../../store/gameStore';
import { getItemColor, getPropModelInfo, getPropScale, getItemsByCategory, getPropBaseHeight } from '../../config/blockConfig';
import * as PropModels from '../../models/props';
import { useSettingsStore } from '../../store/settingsStore';

// Memoized component to prevent unnecessary renders
const PlacementPreview: React.FC = () => {
  // Get preview data from store
  const placementPreview = useGameStore(state => state.placementPreview);
  const settings = useSettingsStore();
  
  // For animation performance optimization
  const lastAnimationTime = useRef<number>(0);
  const ANIMATION_INTERVAL = 100; // Update animation at most 10 times per second
  
  // Set up all refs first, regardless of whether we have a preview
  const opacityPulse = useRef<number>(0);
  const materialRef = useRef<THREE.MeshStandardMaterial | null>(null);
  const materialOverlayRef = useRef<THREE.MeshStandardMaterial | null>(null);
  
  // Create placeholder values to use when no preview exists
  // These values will be overridden when a preview exists
  const defaultColor = "#cccccc";
  
  // Create all materials unconditionally
  const blockMaterial = useMemo(() => {
    const mat = new THREE.MeshStandardMaterial({
      color: defaultColor,
      transparent: true,
      opacity: 0.5,
      depthWrite: false,
    });
    
    materialRef.current = mat;
    return mat;
  }, []);
  
  const overlayMaterial = useMemo(() => {
    const mat = new THREE.MeshStandardMaterial({
      color: defaultColor,
      transparent: true,
      opacity: 0.3,
      depthWrite: false,
    });
    
    materialOverlayRef.current = mat;
    return mat;
  }, []);
  
  // Store component state
  const [renderData, setRenderData] = useState<{
    position: [number, number, number];
    adjustedY: number;
    isBlockLike: boolean;
    component?: any;
    scale: number;
    valid: boolean;
  } | null>(null);
  
  // Update materials and render data when preview changes
  useEffect(() => {
    if (!placementPreview) {
      setRenderData(null);
      return;
    }
    
    const { position, type, valid } = placementPreview;
    const { x, y, z } = position;
    
    // Determine block type
    const blockItems = getItemsByCategory('blocks');
    const isBlockLike = blockItems.includes(type);
    
    // Get color and update materials
    const color = getItemColor(type);
    const invalidColor = "#ff0000";
    const displayColor = valid ? color : invalidColor;
    
    // Update materials
    if (materialRef.current) {
      materialRef.current.color.set(displayColor);
      materialRef.current.opacity = valid ? 0.6 : 0.4;
      
      // Performance optimization: Only use transparent materials when needed
      // Use depthWrite true for valid placements to reduce rendering complexity
      materialRef.current.depthWrite = valid;
    }
    
    if (materialOverlayRef.current) {
      materialOverlayRef.current.color.set(displayColor);
      materialOverlayRef.current.opacity = valid ? 0.3 : 0.4;
      materialOverlayRef.current.depthWrite = valid;
    }
    
    // Update render data
    if (isBlockLike) {
      setRenderData({
        position: [x, y, z],
        adjustedY: y,
        isBlockLike: true,
        scale: 1.0,
        valid
      });
    } else {
      // Handle prop-like items
      const modelInfo = getPropModelInfo(type);
      if (modelInfo && modelInfo.type === 'component') {
        const Component = PropModels[modelInfo.path as keyof typeof PropModels];
        if (Component) {
          const defaultScale = getPropScale(type);
          const baseHeight = getPropBaseHeight(type);
          const adjustedY = y - baseHeight;
          
          setRenderData({
            position: [x, adjustedY, z],
            adjustedY,
            isBlockLike: false,
            component: Component,
            scale: defaultScale,
            valid
          });
        } else {
          setRenderData(null);
        }
      } else {
        setRenderData(null);
      }
    }
  }, [placementPreview]);
  
  // Animate the opacity for a pulsing effect
  // This is optimized to run less frequently for better performance
  useFrame((_, delta) => {
    if (!placementPreview || !materialRef.current || !materialOverlayRef.current) return;
    
    // Only update the animation at specified intervals
    const now = performance.now();
    if (now - lastAnimationTime.current < ANIMATION_INTERVAL) {
      return;
    }
    
    // Update the pulse animation
    opacityPulse.current += delta * 2;
    if (opacityPulse.current > Math.PI * 2) {
      opacityPulse.current = 0;
    }
    
    // Calculate pulse value - reduced intensity for performance
    const pulse = (Math.sin(opacityPulse.current) + 1) / 6; // 0.0 to 0.33 range
    const baseOpacity = placementPreview.valid ? 0.6 : 0.4;
    
    // Update material opacities
    materialRef.current.opacity = baseOpacity + pulse;
    materialOverlayRef.current.opacity = placementPreview.valid ? 0.3 + pulse : 0.4 + pulse;
    
    // Remember when we last updated the animation
    lastAnimationTime.current = now;
  });
  
  // If no render data, don't render anything
  if (!renderData) return null;
  
  // Use simplified rendering for low-end devices
  const useSimplifiedRendering = settings.performanceMode;
  
  // Render a block-like item
  if (renderData.isBlockLike) {
    return (
      <mesh position={renderData.position} rotation={[0, 0, 0]}>
        <boxGeometry args={[1, 1, 1]} />
        <primitive object={blockMaterial} attach="material" />
      </mesh>
    );
  }
  
  // Render a prop-like item - simplified version if in performance mode
  if (renderData.component) {
    const Component = renderData.component;
    
    // In performance mode, just show simple box instead of complex model
    if (useSimplifiedRendering) {
      return (
        <mesh position={renderData.position} scale={[renderData.scale, renderData.scale, renderData.scale]}>
          <boxGeometry args={[1, 1, 1]} />
          <primitive object={blockMaterial} attach="material" />
        </mesh>
      );
    }
    
    // Regular rendering
    return (
      <group
        position={renderData.position}
        rotation={[0, 0, 0]}
        scale={[renderData.scale, renderData.scale, renderData.scale]}
      >
        <Component />
        <mesh scale={[1.05, 1.05, 1.05]}>
          <boxGeometry args={[1, 1, 1]} />
          <primitive object={overlayMaterial} attach="material" />
        </mesh>
      </group>
    );
  }
  
  return null;
};

// Export a memoized version to prevent unnecessary re-renders
export default React.memo(PlacementPreview); 