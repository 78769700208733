import React from 'react';

const SimpleTent: React.FC = () => {
  // Colors
  const tentColor = "#8B4513"; // SaddleBrown
  const floorColor = "#A0522D"; // Sienna

  return (
    <group position={[0, -0.05, 0]}>
      {/* Base/floor */}
      <mesh position={[0, 0.05, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.4, 0.1, 1.4]} />
        <meshStandardMaterial color={floorColor} />
      </mesh>

      {/* Main tent structure */}
      <mesh position={[0, 0.7, 0]} rotation={[0, Math.PI / 4, 0]} castShadow receiveShadow>
        <coneGeometry args={[1, 1.4, 4]} />
        <meshStandardMaterial color={tentColor} />
      </mesh>

      {/* Entrance flap */}
      <mesh position={[0, 0.5, 0.7]} castShadow receiveShadow>
        <boxGeometry args={[0.6, 1, 0.05]} />
        <meshStandardMaterial color="#a0522d" />
      </mesh>

      {/* Support poles */}
      <mesh position={[0, 0.7, 0]} castShadow receiveShadow>
        <cylinderGeometry args={[0.05, 0.05, 1.4]} />
        <meshStandardMaterial color="#6d4c41" />
      </mesh>
    </group>
  );
};

export default SimpleTent; 