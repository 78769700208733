import React, { useRef, useEffect, useCallback, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useGameStore } from '../../store/gameStore';
import { getItemColor, getItemsByCategory, isItemStackable } from '../../config/blockConfig';
import { useMenuContext } from './Item';
import { useSettingsStore } from '../../store/settingsStore';

const InstancedBlocks: React.FC = () => {
  // Get game state from store
  const items = useGameStore(state => state.items);
  const visibleItems = useGameStore(state => state.visibleItems);
  const placementPreview = useGameStore(state => state.placementPreview);
  const setPlacementPreview = useGameStore(state => state.setPlacementPreview);
  const selectedItemType = useGameStore(state => state.selectedItemType);
  const buildMode = useGameStore(state => state.buildMode);
  const addItem = useGameStore(state => state.addItem);
  
  // Get settings
  const memoryOptimization = useSettingsStore(state => state.memoryOptimization);
  
  // Debug flag - change to true only when debugging memory issues
  const DEBUG_MEMORY = false;
  
  // Get menu context
  const { openMenu, isMenuOpen } = useMenuContext();
  
  // Get Three.js objects
  const { scene, camera, raycaster, gl } = useThree();
  
  // References for instanced meshes and matrices
  const instancedMeshesRef = useRef<Record<string, THREE.InstancedMesh>>({});
  const idToInstanceMap = useRef<Record<string, { type: string, index: number }>>({});
  const instanceToIdMap = useRef<Record<string, string>>({});
  const dummyMatrix = useRef(new THREE.Matrix4());
  const blockTypes = getItemsByCategory('blocks');
  const pointer = useRef(new THREE.Vector2());
  const mouseDownRef = useRef(false);
  const rightMouseDownRef = useRef(false);
  const lastPreviewState = useRef<any>(null);
  const lastUpdateTime = useRef<number>(0);
  const lastStatsLogTime = useRef<number>(0); // Separate timestamp for stats logging
  const previewUpdateRequested = useRef<boolean>(false);
  
  // Track instance allocation needs
  const [instanceCapacity, setInstanceCapacity] = useState(() => 
    memoryOptimization ? Math.max(100, Math.min(3000, Object.keys(items).length * 3)) : 10000
  );
  const [lastCapacityChangeTime, setLastCapacityChangeTime] = useState(0);
  const CAPACITY_CHANGE_THROTTLE = 5000; // Allow changes at most every 5 seconds
  
  // Track warning throttling to avoid console spam
  const warningLogTimes = useRef<Record<string, number>>({});
  const WARNING_THROTTLE = 5000; // Only log the same warning every 5 seconds
  
  // Track which block types are exceeding their allocation
  const blockTypeExceededRef = useRef<Record<string, boolean>>({});
  const needsCapacityIncreaseRef = useRef<boolean>(false);
  
  // Track mouse/touch movement to prevent accidental placement during camera movement
  const pointerDownPosition = useRef<{ x: number, y: number } | null>(null);
  const hasMovedSignificantly = useRef<boolean>(false);
  const MOVEMENT_THRESHOLD = 5; // Pixels threshold for considering a significant movement
  
  // Track mouse/touch movement to prevent accidental placement during camera movement
  const pointerMovedSignificantly = useRef<boolean>(false);
  const pointerMovedSignificantlyCount = useRef<number>(0);
  const pointerMovedSignificantlyThreshold = useRef<number>(0.01);
  
  // Initial capacity setup - more accurate after first render
  useEffect(() => {
    // Only run once on mount to set an appropriate initial capacity
    const blockTypeCounts: Record<string, number> = {};
    
    // Count items per block type
    Object.values(items).forEach(item => {
      if (blockTypes.includes(item.type)) {
        blockTypeCounts[item.type] = (blockTypeCounts[item.type] || 0) + 1;
      }
    });
    
    // Find the max count for any block type
    const maxBlockTypeCount = Object.values(blockTypeCounts).reduce(
      (max, count) => Math.max(max, count), 0
    );
    
    // Set initial capacity based on actual usage
    if (memoryOptimization) {
      // For memory optimization, scale based on actual block counts with buffer
      const newCapacity = Math.max(
        100, 
        Math.min(7000, maxBlockTypeCount * 1.5)
      );
      
      if (DEBUG_MEMORY) {
        console.log(`Initial capacity set to ${newCapacity} (max per block type: ${maxBlockTypeCount})`);
      }
      setInstanceCapacity(Math.ceil(newCapacity));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/* Run only on mount */]);
  
  // Handle block click
  const handleBlockClick = useCallback((clientX: number, clientY: number, isRightClick: boolean) => {
    if (isMenuOpen) return;
    
    // First check if we have a valid placement preview (left-click only, not for right-clicks)
    if (!isRightClick && buildMode && selectedItemType && placementPreview && placementPreview.valid) {
      // Use the preview position for placement
      const { x, y, z } = placementPreview.position;
      
      try {
        // Add the new item - use console log for debugging
        console.log("InstancedBlocks: Adding item", x, y, z, selectedItemType);
        addItem(x, y, z, selectedItemType);
      } catch (err) {
        console.error("Error adding item:", err);
      }
      return;
    }
    
    // Handle right-click or non-build-mode clicks
    
    // Normalize coordinates
    const x = (clientX / window.innerWidth) * 2 - 1;
    const y = -(clientY / window.innerHeight) * 2 + 1;
    
    // Update raycaster with a Vector2
    raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
    
    // Check all instanced meshes
    let closestIntersection: THREE.Intersection | null = null;
    let closestItemId: string | null = null;
    
    // Find the closest intersection across all instanced meshes
    for (const blockType of blockTypes) {
      const mesh = instancedMeshesRef.current[blockType];
      if (!mesh || mesh.count === 0) continue;
      
      // @ts-ignore - TypeScript definition mismatch with actual Three.js API
      const intersections = raycaster.intersectObjects([mesh], false);
      if (intersections.length > 0) {
        const intersection = intersections[0];
        
        if (!closestIntersection || intersection.distance < closestIntersection.distance) {
          closestIntersection = intersection;
          
          // Find which instance was hit
          const instanceId = intersection.instanceId;
          if (instanceId !== undefined) {
            // Find the item ID for this instance
            const key = `${blockType}-${instanceId}`;
            const itemId = instanceToIdMap.current[key];
            if (itemId) {
              closestItemId = itemId;
            }
          }
        }
      }
    }
    
    if (closestItemId && closestIntersection) {
      if (isRightClick) {
        // Right-click opens the item menu
        const item = items[closestItemId];
        if (item) {
          // Convert world position to screen position for menu placement
          const position = new THREE.Vector3(
            item.position.x, 
            item.position.y, 
            item.position.z
          );
          
          // Project position to screen space
          position.project(camera);
          
          // Convert to pixel coordinates
          const x = (position.x * 0.5 + 0.5) * window.innerWidth;
          const y = (-(position.y * 0.5) + 0.5) * window.innerHeight;
          
          console.log("Opening menu for item:", closestItemId);
          // Open menu
          openMenu(closestItemId, { x, y }, item.rotatable || false, item.type);
        }
      } else if (buildMode) {
        // Left-click in build mode without a valid preview does nothing
        return;
      } else {
        // Left-click in non-build mode also opens the menu
        const item = items[closestItemId];
        if (item) {
          // Convert world position to screen position for menu placement
          const position = new THREE.Vector3(
            item.position.x, 
            item.position.y, 
            item.position.z
          );
          
          // Project position to screen space
          position.project(camera);
          
          // Convert to pixel coordinates
          const x = (position.x * 0.5 + 0.5) * window.innerWidth;
          const y = (-(position.y * 0.5) + 0.5) * window.innerHeight;
          
          console.log("Opening menu for item:", closestItemId);
          // Open menu
          openMenu(closestItemId, { x, y }, item.rotatable || false, item.type);
        }
      }
    }
  }, [isMenuOpen, buildMode, selectedItemType, placementPreview, addItem, raycaster, camera, blockTypes, items, openMenu]);
  
  // Create and manage instanced meshes
  useEffect(() => {
    // Create a box geometry that will be shared by all instances
    const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
    const meshesCreated: Record<string, THREE.InstancedMesh> = {};
    
    // Create an instanced mesh for each block type
    blockTypes.forEach(blockType => {
      const color = getItemColor(blockType);
      const material = new THREE.MeshStandardMaterial({ color });
      
      // Create an instanced mesh with capacity based on current state
      const instancedMesh = new THREE.InstancedMesh(
        boxGeometry,
        material,
        instanceCapacity
      );
      
      // Initialize with 0 visible instances
      instancedMesh.count = 0;
      instancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
      instancedMesh.castShadow = true;
      instancedMesh.receiveShadow = true;
      
      // Store in ref
      instancedMeshesRef.current[blockType] = instancedMesh;
      meshesCreated[blockType] = instancedMesh;
      
      // Add to scene
      scene.add(instancedMesh);
    });
    
    return () => {
      // Clean up
      Object.entries(meshesCreated).forEach(([blockType, mesh]) => {
        if (mesh) {
          scene.remove(mesh);
          mesh.geometry.dispose();
          
          // Handle material disposal safely
          if (mesh.material) {
            if (Array.isArray(mesh.material)) {
              mesh.material.forEach(mat => mat.dispose());
            } else {
              mesh.material.dispose();
            }
          }
        }
      });
    };
  }, [blockTypes, scene, instanceCapacity]); // Only recreate when capacity changes

  // Update instance capacity based on world size and memory settings
  // This runs separately from mesh creation to minimize recreation
  useEffect(() => {
    // Only update capacity when needed
    const numItems = Object.keys(items).length;
    const now = performance.now();
    
    // Check throttling - don't update too frequently
    if (now - lastCapacityChangeTime < CAPACITY_CHANGE_THROTTLE) {
      return;
    }
    
    if (memoryOptimization) {
      // Scale allocation based on actual item count plus a buffer for growth
      // For large worlds, allow up to 5000 instances per block type
      const newCapacity = Math.max(100, Math.min(5000, numItems * 2));
      
      // Only update if there's a significant change to avoid frequent recreations
      if (Math.abs(newCapacity - instanceCapacity) > 200) {
        if (DEBUG_MEMORY) {
          console.log(`Memory optimization: Adjusting instance capacity from ${instanceCapacity} to ${newCapacity} for ${numItems} items`);
        }
        setInstanceCapacity(newCapacity);
        setLastCapacityChangeTime(now);
      }
    } else if (instanceCapacity !== 10000) {
      // For non-memory optimization mode, use fixed large capacity
      if (DEBUG_MEMORY) {
        console.log(`Memory optimization disabled: Restoring instance capacity to 10000`);
      }
      setInstanceCapacity(10000);
      setLastCapacityChangeTime(now);
    }
  }, [items, memoryOptimization, instanceCapacity, lastCapacityChangeTime, DEBUG_MEMORY]);
  
  // Automatically increase capacity if we detect instance limits being exceeded
  useEffect(() => {
    // Only run if memory optimization is on and we need more capacity
    if (!memoryOptimization || !needsCapacityIncreaseRef.current) {
      return;
    }
    
    const now = performance.now();
    
    // Don't increase capacity too frequently
    if (now - lastCapacityChangeTime < CAPACITY_CHANGE_THROTTLE) {
      return;
    }
    
    // Calculate a more appropriate capacity based on current usage
    const numItems = Object.keys(items).length;
    const exceededCount = Object.keys(blockTypeExceededRef.current).length;
    
    if (exceededCount > 0) {
      // Increase by at least 50% or more depending on how many types are exceeding
      const newCapacity = Math.max(
        instanceCapacity * 1.5,
        Math.min(10000, numItems * 3)
      );
      
      // Always log capacity increases, even in non-debug mode, since they're important
      console.log(`Auto-increasing instance capacity from ${instanceCapacity} to ${newCapacity} due to ${exceededCount} block types exceeding limits`);
      
      // Update capacity
      setInstanceCapacity(Math.floor(newCapacity));
      setLastCapacityChangeTime(now);
      
      // Reset flags
      blockTypeExceededRef.current = {};
      needsCapacityIncreaseRef.current = false;
    }
  }, [instanceCapacity, items, lastCapacityChangeTime, memoryOptimization, DEBUG_MEMORY]);
  
  // Set up mouse event handlers
  useEffect(() => {
    const onPointerDown = (event: MouseEvent) => {
      // Normalize coordinates
      pointer.current.x = (event.clientX / window.innerWidth) * 2 - 1;
      pointer.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
      
      // Store the initial position for movement detection
      pointerDownPosition.current = { x: event.clientX, y: event.clientY };
      hasMovedSignificantly.current = false;
      
      if (event.button === 0) {
        mouseDownRef.current = true;
      } else if (event.button === 2) {
        rightMouseDownRef.current = true;
      }
    };
    
    const onPointerUp = (event: MouseEvent) => {
      // Check if mouse has moved significantly since mousedown (camera movement)
      const hasMoved = checkSignificantMovement(event.clientX, event.clientY);
      
      if (event.button === 0) {
        mouseDownRef.current = false;
        
        // Only handle click events for blocks if the pointer hasn't moved much
        if (!hasMoved) {
          handleBlockClick(event.clientX, event.clientY, false);
        }
      } else if (event.button === 2) {
        // Handle right-click events
        if (!hasMoved) {
          handleBlockClick(event.clientX, event.clientY, true);
        }
        rightMouseDownRef.current = false;
      }
      
      // Reset tracking
      pointerDownPosition.current = null;
    };
    
    const onPointerMove = (event: MouseEvent) => {
      // Update pointer coordinates
      const newX = (event.clientX / window.innerWidth) * 2 - 1;
      const newY = -(event.clientY / window.innerHeight) * 2 + 1;
      
      // Check if pointer has moved significantly
      const dx = Math.abs(newX - pointer.current.x);
      const dy = Math.abs(newY - pointer.current.y);
      if (dx > pointerMovedSignificantlyThreshold.current || dy > pointerMovedSignificantlyThreshold.current) {
        pointerMovedSignificantly.current = true;
        pointerMovedSignificantlyCount.current++;
        
        // Update current pointer position
        pointer.current.x = newX;
        pointer.current.y = newY;
        
        // Immediately request a preview update on significant movement
        if (buildMode && !isMenuOpen) {
          previewUpdateRequested.current = true;
        }
      }
      
      // Check for significant movement (for click prevention)
      if (pointerDownPosition.current) {
        const dx = Math.abs(event.clientX - pointerDownPosition.current.x);
        const dy = Math.abs(event.clientY - pointerDownPosition.current.y);
        
        if (dx > MOVEMENT_THRESHOLD || dy > MOVEMENT_THRESHOLD) {
          hasMovedSignificantly.current = true;
        }
      }
    };
    
    // Handle touch events
    const onTouchStart = (event: TouchEvent) => {
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        pointer.current.x = (touch.clientX / window.innerWidth) * 2 - 1;
        pointer.current.y = -(touch.clientY / window.innerHeight) * 2 + 1;
        
        // Store the initial position for movement detection
        pointerDownPosition.current = { x: touch.clientX, y: touch.clientY };
        hasMovedSignificantly.current = false;
      }
    };
    
    const onTouchMove = (event: TouchEvent) => {
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        pointer.current.x = (touch.clientX / window.innerWidth) * 2 - 1;
        pointer.current.y = -(touch.clientY / window.innerHeight) * 2 + 1;
        
        // Check if we've moved significantly from the starting point
        checkSignificantMovement(touch.clientX, touch.clientY);
        
        // Request preview update on next frame
        previewUpdateRequested.current = true;
      }
    };
    
    const onTouchEnd = (event: TouchEvent) => {
      // Handle touch end for block placement
      if (event.changedTouches.length > 0) {
        const touch = event.changedTouches[0];
        
        // Only place blocks if the touch hasn't moved much (not a camera movement)
        if (!hasMovedSignificantly.current) {
          handleBlockClick(touch.clientX, touch.clientY, false);
        }
      }
      
      // Reset tracking
      pointerDownPosition.current = null;
    };
    
    // Add context menu handler to prevent the default browser context menu
    const onContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };
    
    // Helper function to check if pointer has moved significantly
    const checkSignificantMovement = (currentX: number, currentY: number): boolean => {
      if (pointerDownPosition.current) {
        const dx = currentX - pointerDownPosition.current.x;
        const dy = currentY - pointerDownPosition.current.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        if (distance > MOVEMENT_THRESHOLD) {
          hasMovedSignificantly.current = true;
        }
      }
      
      return hasMovedSignificantly.current;
    };
    
    // Add event listeners
    gl.domElement.addEventListener('pointerdown', onPointerDown);
    gl.domElement.addEventListener('pointerup', onPointerUp);
    gl.domElement.addEventListener('pointermove', onPointerMove);
    gl.domElement.addEventListener('touchstart', onTouchStart, { passive: true });
    gl.domElement.addEventListener('touchmove', onTouchMove, { passive: true });
    gl.domElement.addEventListener('touchend', onTouchEnd);
    gl.domElement.addEventListener('contextmenu', onContextMenu);
    
    return () => {
      // Remove event listeners
      gl.domElement.removeEventListener('pointerdown', onPointerDown);
      gl.domElement.removeEventListener('pointerup', onPointerUp);
      gl.domElement.removeEventListener('pointermove', onPointerMove);
      gl.domElement.removeEventListener('touchstart', onTouchStart);
      gl.domElement.removeEventListener('touchmove', onTouchMove);
      gl.domElement.removeEventListener('touchend', onTouchEnd);
      gl.domElement.removeEventListener('contextmenu', onContextMenu);
    };
  }, [gl.domElement, buildMode, isMenuOpen, addItem, selectedItemType, placementPreview, handleBlockClick]);
  
  // Update placement preview when hovering over blocks
  const updatePlacementPreview = () => {
    // Only run this if we're in build mode, have an item selected, and the menu is closed
    if (!buildMode || !selectedItemType || isMenuOpen) {
      return false;
    }
    
    // Use current pointer position for raycasting
    raycaster.setFromCamera(pointer.current, camera);
    
    // Check all instanced meshes
    let closestIntersection: THREE.Intersection | null = null;
    let hitBlockType: string | null = null;
    let hitInstanceId: number | null = null;
    
    // Find the closest intersection across all instanced meshes
    for (const blockType of blockTypes) {
      const mesh = instancedMeshesRef.current[blockType];
      if (!mesh || mesh.count === 0) continue;
      
      // @ts-ignore - TypeScript definition mismatch with actual Three.js API
      const intersections = raycaster.intersectObjects([mesh], false);
      if (intersections.length > 0) {
        const intersection = intersections[0];
        
        if (!closestIntersection || intersection.distance < closestIntersection.distance) {
          closestIntersection = intersection;
          hitBlockType = blockType;
          hitInstanceId = intersection.instanceId !== undefined ? intersection.instanceId : null;
        }
      }
    }
    
    // If we have a valid intersection
    if (closestIntersection && closestIntersection.face && hitBlockType !== null && hitInstanceId !== null) {
      // Find the item that was hit
      const key = `${hitBlockType}-${hitInstanceId}`;
      const itemId = instanceToIdMap.current[key];
      
      if (itemId && items[itemId]) {
        const targetItem = items[itemId];
        
        // Determine which face was hit (0-5)
        // This matches the face index from BoxGeometry: +X, -X, +Y, -Y, +Z, -Z
        const faceNormal = closestIntersection.face.normal.clone();
        // Convert from object space to world space
        const objRotationY = targetItem.rotation || 0;
        const rotationMatrix = new THREE.Matrix4().makeRotationY(objRotationY);
        faceNormal.applyMatrix4(rotationMatrix);
        
        // Get the most prominent axis
        const absX = Math.abs(faceNormal.x);
        const absY = Math.abs(faceNormal.y);
        const absZ = Math.abs(faceNormal.z);
        
        let face: number;
        if (absX >= absY && absX >= absZ) {
          face = faceNormal.x > 0 ? 0 : 1; // +X or -X
        } else if (absY >= absX && absY >= absZ) {
          face = faceNormal.y > 0 ? 2 : 3; // +Y or -Y
        } else {
          face = faceNormal.z > 0 ? 4 : 5; // +Z or -Z
        }
        
        // Calculate directional offsets based on which face was hit
        const [dx, dy, dz] = [
          face === 0 ? 1 : face === 1 ? -1 : 0,
          face === 2 ? 1 : face === 3 ? -1 : 0,
          face === 4 ? 1 : face === 5 ? -1 : 0
        ];
        
        // Target and selected item properties
        const targetIsStackable = targetItem.stackable !== false; // Default to true if not specified
        const selectedIsStackable = isItemStackable(selectedItemType);
        const isValid = targetIsStackable || (!targetIsStackable && selectedIsStackable && face === 2);
        
        // Prepare new preview state
        let newPreviewState = null;
        
        // Create placement preview
        if (targetIsStackable) {
          // For stackable items, show preview on any face
          newPreviewState = {
            position: { 
              x: targetItem.position.x + dx, 
              y: targetItem.position.y + dy, 
              z: targetItem.position.z + dz 
            },
            type: selectedItemType,
            valid: isValid,
            targetItemId: itemId,
            placementFace: face,
            source: 'instanced' as const
          };
        } else if (face === 2) {
          // For non-stackable items, only allow placement on top
          newPreviewState = {
            position: { 
              x: targetItem.position.x, 
              y: targetItem.position.y + 1, 
              z: targetItem.position.z 
            },
            type: selectedItemType,
            valid: isValid,
            targetItemId: itemId,
            placementFace: face,
            source: 'instanced' as const
          };
        }
        
        // Only update if the preview has changed to avoid unnecessary re-renders
        if (newPreviewState) {
          if (!lastPreviewState.current || 
              JSON.stringify(newPreviewState) !== JSON.stringify(lastPreviewState.current)) {
            lastPreviewState.current = newPreviewState;
            setPlacementPreview(newPreviewState);
          }
          return true;
        }
      }
    }
    
    return false;
  };
  
  // Update instances and handle preview on each frame
  useFrame((_, delta) => {
    // Get current time
    const now = performance.now();
    
    // Reset mapping and counts
    idToInstanceMap.current = {};
    instanceToIdMap.current = {};
    const blockCounts: Record<string, number> = {};
    const matrixUpdateNeeded: Record<string, boolean> = {};
    
    // Initialize counts and update flags
    blockTypes.forEach(type => {
      blockCounts[type] = 0;
      matrixUpdateNeeded[type] = false;
    });
    
    // Performance optimization: Create a map for fast lookup of visible items
    const visibleItemsLookup = new Set(visibleItems);
    let totalVisibleBlocks = 0;
    
    // PERFORMANCE OPTIMIZATION:
    // Process items in batches for large worlds to avoid long frames
    const visibleItemEntries = Object.entries(items).filter(
      ([id]) => visibleItemsLookup.has(id)
    );
    
    // Process visible items
    visibleItemEntries.forEach(([id, item]) => {
      // Skip non-block items
      if (!blockTypes.includes(item.type)) return;
      
      totalVisibleBlocks++;
      const { x, y, z } = item.position;
      const rotation = item.rotation || 0;
      
      // Memory optimization: Only create matrices when actually needed
      const meshForType = instancedMeshesRef.current[item.type];
      if (!meshForType) return;
      
      const instanceIndex = blockCounts[item.type]++;
      
      // Set position and rotation
      dummyMatrix.current.makeRotationY(rotation);
      dummyMatrix.current.setPosition(x, y, z);
      
      // Update the instance
      if (meshForType) {
        // Safety check: don't exceed the instanced mesh capacity
        if (instanceIndex < meshForType.instanceMatrix.count) {
          meshForType.setMatrixAt(instanceIndex, dummyMatrix.current);
          matrixUpdateNeeded[item.type] = true;
          
          // Store mappings for interactions
          idToInstanceMap.current[id] = { type: item.type, index: instanceIndex };
          instanceToIdMap.current[`${item.type}-${instanceIndex}`] = id;
        } else {
          // Track which block types are exceeding their allocation
          blockTypeExceededRef.current[item.type] = true;
          needsCapacityIncreaseRef.current = true;
          
          // Throttle warning messages to prevent console spam
          const warningKey = `instance_limit_${item.type}`;
          
          if (!warningLogTimes.current[warningKey] || 
              now - warningLogTimes.current[warningKey] > WARNING_THROTTLE) {
            // Only log warning if we haven't recently logged it for this block type
            console.warn(`Exceeded max instances (${meshForType.instanceMatrix.count}) for block type: ${item.type}`);
            warningLogTimes.current[warningKey] = now;
            
            // If this is happening, we should consider increasing capacity
            console.log(`Consider increasing capacity. Current: ${instanceCapacity}, Total items: ${Object.keys(items).length}`);
          }
        }
      }
    });
    
    // Log memory usage stats occasionally
    const STATS_LOG_INTERVAL = 30000; // Only log every 30 seconds instead of 5 seconds
    if (DEBUG_MEMORY && now - lastStatsLogTime.current > STATS_LOG_INTERVAL) {
      console.log(`Memory stats: ${totalVisibleBlocks} visible blocks, capacity: ${instanceCapacity}`);
      lastStatsLogTime.current = now; // Update stats log time separately
    }
    
    // Update counts and instance matrices only if there are changes
    blockTypes.forEach(type => {
      const mesh = instancedMeshesRef.current[type];
      if (mesh) {
        const newCount = blockCounts[type];
        if (mesh.count !== newCount) {
          mesh.count = newCount;
          matrixUpdateNeeded[type] = true;
        }
        
        // Only update instance matrices if actually needed
        if (matrixUpdateNeeded[type]) {
          mesh.instanceMatrix.needsUpdate = true;
        }
      }
    });
    
    // Check for placement preview updates
    if (buildMode && selectedItemType) {
      const previewShown = updatePlacementPreview();
      
      if (!previewShown && lastPreviewState.current && placementPreview?.source === 'instanced') {
        lastPreviewState.current = null;
        setPlacementPreview(null);
      }
      
      // Reset flags after updating
      previewUpdateRequested.current = false;
      pointerMovedSignificantly.current = false;
    }
    
    // Update frame timestamp
    lastUpdateTime.current = now;
  });
  
  // Empty render since the meshes are added directly to the scene
  return null;
};

export default InstancedBlocks; 