import React from 'react';

const WoodenGate: React.FC = () => {
  // Colors
  const woodColor = "#8B4513"; // SaddleBrown
  const darkWoodColor = "#654321"; // Dark brown

  return (
    <group position={[0, -0.2, 0]}>
      {/* Gate posts */}
      <mesh position={[-0.45, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.8, 0.1]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>
      <mesh position={[0.45, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.8, 0.1]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>

      {/* Gate frame */}
      <mesh position={[0, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.9, 0.7, 0.05]} />
        <meshStandardMaterial color={darkWoodColor} />
      </mesh>

      {/* Gate cross beams */}
      <mesh position={[0, 0.4, 0.01]} rotation={[0, 0, Math.PI / 4]} castShadow receiveShadow>
        <boxGeometry args={[0.9, 0.08, 0.03]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>
      <mesh position={[0, 0.4, 0.01]} rotation={[0, 0, -Math.PI / 4]} castShadow receiveShadow>
        <boxGeometry args={[0.9, 0.08, 0.03]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>

      {/* Gate horizontal beams */}
      <mesh position={[0, 0.1, 0.01]} castShadow receiveShadow>
        <boxGeometry args={[0.9, 0.08, 0.03]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>
      <mesh position={[0, 0.7, 0.01]} castShadow receiveShadow>
        <boxGeometry args={[0.9, 0.08, 0.03]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>

      {/* Gate planks */}
      {[-0.27, -0.13, 0, 0.13, 0.27].map((x, i) => (
        <mesh key={i} position={[x, 0.4, 0]} castShadow receiveShadow>
          <boxGeometry args={[0.12, 0.7, 0.04]} />
          <meshStandardMaterial color={woodColor} />
        </mesh>
      ))}

      {/* Hinges */}
      <mesh position={[-0.4, 0.6, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.04, 0.08]} />
        <meshStandardMaterial color="#4a4a4a" />
      </mesh>
      <mesh position={[-0.4, 0.2, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.04, 0.08]} />
        <meshStandardMaterial color="#4a4a4a" />
      </mesh>

      {/* Latch */}
      <mesh position={[0.4, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.08, 0.15, 0.08]} />
        <meshStandardMaterial color="#4a4a4a" />
      </mesh>
    </group>
  );
};

export default WoodenGate; 