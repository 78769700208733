import React, { useEffect } from 'react';
import { useGameStore } from '../../store/gameStore';
import { ItemType } from '../../types';
import { getItemName } from '../../config/blockConfig';
import './ItemMenu.css';

interface ItemMenuProps {
  itemId: string;
  position: { x: number, y: number };
  isRotatable: boolean;
  itemType: ItemType;
  onClose: () => void;
}

const ItemMenu: React.FC<ItemMenuProps> = ({ itemId, position, isRotatable, itemType, onClose }) => {
  const removeItem = useGameStore(state => state.removeItem);
  const rotateItem = useGameStore(state => state.rotateItem);
  
  // Get the item name
  const itemName = getItemName(itemType);
  
  // Handle clicks
  const handleRotateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    rotateItem(itemId);
    onClose();
  };
  
  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeItem(itemId);
    onClose();
  };
  
  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };
  
  // Create an overlay that captures all clicks outside the menu
  useEffect(() => {
    // Create an overlay div that covers the entire screen
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.zIndex = '9998'; // Just below menu zIndex
    document.body.appendChild(overlay);
    
    // When clicking the overlay, close the menu
    const handleOverlayClick = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onClose();
    };
    
    overlay.addEventListener('click', handleOverlayClick);
    overlay.addEventListener('contextmenu', handleOverlayClick);
    
    // Cleanup function
    return () => {
      overlay.removeEventListener('click', handleOverlayClick);
      overlay.removeEventListener('contextmenu', handleOverlayClick);
      document.body.removeChild(overlay);
    };
  }, [onClose]);
  
  return (
    <div 
      className="item-menu" 
      style={{ 
        position: 'fixed',
        left: position.x,
        top: position.y,
        zIndex: 9999
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="item-menu-header">
        <h3>{itemName}</h3>
      </div>
      
      {isRotatable && (
        <button className="item-menu-button" onClick={handleRotateClick}>
          <span className="item-menu-icon">🔄</span>
          <span className="item-menu-label">Rotate</span>
        </button>
      )}
      <button className="item-menu-button" onClick={handleDeleteClick}>
        <span className="item-menu-icon">🗑️</span>
        <span className="item-menu-label">Delete</span>
      </button>
      <button className="item-menu-button" onClick={handleCloseClick}>
        <span className="item-menu-icon">✖️</span>
        <span className="item-menu-label">Close</span>
      </button>
    </div>
  );
};

export default ItemMenu;
