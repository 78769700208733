import React from 'react';
import { Cylinder, Cone } from '@react-three/drei';

const SimpleTree: React.FC = () => {
  return (
    <group position={[0, 0, 0]}>
      {/* Tree trunk - positioned from base */}
      <Cylinder 
        args={[0.2, 0.3, 1.5, 8]} 
        position={[0, 0.75, 0]}
      >
        <meshStandardMaterial color="#8B4513" />
      </Cylinder>
      
      {/* Tree foliage - positioned relative to trunk */}
      <Cone 
        args={[1, 2, 8]} 
        position={[0, 2, 0]}
      >
        <meshStandardMaterial color="#228B22" />
      </Cone>
      <Cone 
        args={[0.8, 1.5, 8]} 
        position={[0, 2.8, 0]}
      >
        <meshStandardMaterial color="#228B22" />
      </Cone>
    </group>
  );
};

export default SimpleTree; 