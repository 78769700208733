import React, { useState, useEffect, useRef } from 'react';
import { useGameStore } from '../../store/gameStore';
import { useSettingsStore } from '../../store/settingsStore';
import BuildButton from './BuildButton';
import BlockSelector from './BlockSelector';
import WorldGenerationMenu from './WorldGenerationMenu';
import './UI.css';

const UI: React.FC = () => {
  const [fps, setFps] = useState(0);
  const [activePane, setActivePane] = useState<'game' | 'settings' | 'debug'>('game');
  const [menuVisible, setMenuVisible] = useState(false);
  const [showWorldGenMenu, setShowWorldGenMenu] = useState(false);
  const [occludedBlocks, setOccludedBlocks] = useState(0);
  const [frustumCulledBlocks, setFrustumCulledBlocks] = useState(0);
  
  // Game state
  const items = useGameStore(state => state.items);
  const visibleItems = useGameStore(state => state.visibleItems);
  const reset = useGameStore(state => state.reset);
  const isGeneratingWorld = useGameStore(state => state.isGeneratingWorld);
  const generationProgress = useGameStore(state => state.generationProgress);
  
  // Track instance limits
  const [instanceLimitWarning, setInstanceLimitWarning] = useState<string | null>(null);
  const lastWarningTimeRef = useRef<Record<string, number>>({});
  const WARNING_THROTTLE = 5000; // Only show a warning every 5 seconds
  
  // Settings state
  const showShadows = useSettingsStore(state => state.showShadows);
  const lightingMode = useSettingsStore(state => state.lightingMode);
  const renderDistance = useSettingsStore(state => state.renderDistance);
  const enableOcclusionCulling = useSettingsStore(state => state.enableOcclusionCulling);
  const setShowShadows = useSettingsStore(state => state.setShowShadows);
  const setLightingMode = useSettingsStore(state => state.setLightingMode);
  const setRenderDistance = useSettingsStore(state => state.setRenderDistance);
  const setEnableOcclusionCulling = useSettingsStore(state => state.setEnableOcclusionCulling);
  const performanceMode = useSettingsStore(state => state.performanceMode);
  const setPerformanceMode = useSettingsStore(state => state.setPerformanceMode);
  const memoryOptimization = useSettingsStore(state => state.memoryOptimization);
  const setMemoryOptimization = useSettingsStore(state => state.setMemoryOptimization);
  
  // Listen for instance limit warnings
  useEffect(() => {
    const originalWarn = console.warn;
    
    // Override console.warn to catch instance limit warnings
    console.warn = (...args) => {
      const message = args.join(' ');
      if (message.includes('Exceeded max instances')) {
        // Extract the block type from the warning message
        const match = message.match(/block type: (\w+)/);
        const blockType = match ? match[1] : 'unknown';
        const warningKey = `instance_limit_${blockType}`;
        
        // Check if we've recently shown this warning
        const now = performance.now();
        if (!lastWarningTimeRef.current[warningKey] || 
            now - lastWarningTimeRef.current[warningKey] > WARNING_THROTTLE) {
          // Only update warning if we haven't shown it recently
          setInstanceLimitWarning(message);
          lastWarningTimeRef.current[warningKey] = now;
        }
      }
      originalWarn(...args);
    };
    
    return () => {
      // Restore original console.warn on cleanup
      console.warn = originalWarn;
    };
  }, []);
  
  // Listen for occlusion stats
  useEffect(() => {
    const handleOcclusionStats = (event: CustomEvent) => {
      if (event.detail) {
        if (typeof event.detail.occludedBlocks === 'number') {
          setOccludedBlocks(event.detail.occludedBlocks);
        }
        if (typeof event.detail.frustumCulledBlocks === 'number') {
          setFrustumCulledBlocks(event.detail.frustumCulledBlocks);
        }
      }
    };
    
    // Add event listener
    window.addEventListener('occlusion-stats', handleOcclusionStats as EventListener);
    
    return () => {
      // Clean up event listener
      window.removeEventListener('occlusion-stats', handleOcclusionStats as EventListener);
    };
  }, []);
  
  // FPS Counter
  useEffect(() => {
    let frameCount = 0;
    let lastTime = performance.now();
    
    const updateFPS = () => {
      const currentTime = performance.now();
      frameCount++;
      
      // Update FPS every second
      if (currentTime - lastTime > 1000) {
        setFps(Math.round(frameCount * 1000 / (currentTime - lastTime)));
        frameCount = 0;
        lastTime = currentTime;
      }
      
      requestAnimationFrame(updateFPS);
    };
    
    const animationId = requestAnimationFrame(updateFPS);
    return () => cancelAnimationFrame(animationId);
  }, []);
  
  const totalItems = Object.keys(items).length;
  
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  
  // Function to close menu
  const closeMenu = () => {
    setMenuVisible(false);
  };
  
  return (
    <div className="ui-container">
      {/* Loading overlay */}
      {isGeneratingWorld && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">
            {generationProgress < 25 ? 'Generating Terrain...' :
             generationProgress < 50 ? 'Creating Rivers & Lakes...' :
             generationProgress < 75 ? 'Building World...' :
             generationProgress < 95 ? 'Adding Details...' :
             'Rendering World...'}
            {' '}{generationProgress}%
          </div>
          <div className="progress-bar">
            <div 
              className="progress-bar-fill" 
              style={{ width: `${generationProgress}%` }}
            ></div>
          </div>
        </div>
      )}
      
      {/* World Generation Menu */}
      {showWorldGenMenu && (
        <WorldGenerationMenu onClose={() => setShowWorldGenMenu(false)} />
      )}
      
      {/* Game title and developer credit on the left */}
      <div className="title-section">
        <div className="game-title">Builder v0.1.4</div>
        <div className="developer-credit">
          <a href="https://x.com/sarperdag" target="_blank" rel="noopener noreferrer">
            by @sarperdag
          </a>
        </div>
      </div>
      
      {/* Mini FPS counter when menu is hidden */}
      {!menuVisible && (
        <div className="mini-stats">
          <div className="mini-stat">FPS: {fps}</div>
        </div>
      )}
      
      {/* Menu toggle button - only show when menu is closed */}
      {!menuVisible && (
        <button 
          className="menu-toggle-button" 
          onClick={toggleMenu}
          aria-label="Show Menu"
        >
          ☰
        </button>
      )}
      
      {/* Menu content - only visible when menu is visible */}
      {menuVisible && (
        <div className="menu-overlay">
          <div className="menu-wrapper">
            {/* Menu Tabs */}
            <div className="menu-tabs">
              <button 
                className={`menu-tab ${activePane === 'game' ? 'active' : ''}`}
                onClick={() => setActivePane('game')}
              >
                Game
              </button>
              <button 
                className={`menu-tab ${activePane === 'settings' ? 'active' : ''}`}
                onClick={() => setActivePane('settings')}
              >
                Settings
              </button>
              <button 
                className={`menu-tab ${activePane === 'debug' ? 'active' : ''}`}
                onClick={() => setActivePane('debug')}
              >
                Debug
              </button>
            </div>
            
            {/* Menu Content */}
            <div className="menu-content">
              {/* Game Menu */}
              {activePane === 'game' && (
                <div className="menu-pane">
                  <h3>Game Menu</h3>
                  <div className="menu-item">
                    <button 
                      className="menu-button"
                      onClick={() => {
                        setShowWorldGenMenu(true);
                        closeMenu(); // Close the main menu when opening world gen menu
                      }}
                      disabled={isGeneratingWorld}
                    >
                      {isGeneratingWorld ? 'Generating World...' : 'Generate World'}
                    </button>
                  </div>
                  <div className="menu-item">
                    <button 
                      className="menu-button"
                      onClick={reset}
                      disabled={isGeneratingWorld}
                    >
                      Reset World
                    </button>
                  </div>
                  <div className="menu-item">
                    <button 
                      className="menu-button"
                      onClick={() => alert('Save feature coming soon!')}
                      disabled={isGeneratingWorld}
                    >
                      Save World
                    </button>
                  </div>
                  <div className="menu-item">
                    <button 
                      className="menu-button"
                      onClick={() => alert('Load feature coming soon!')}
                      disabled={isGeneratingWorld}
                    >
                      Load World
                    </button>
                  </div>
                </div>
              )}
              
              {/* Settings Menu */}
              {activePane === 'settings' && (
                <div className="menu-pane">
                  <h3>Settings</h3>
                  <div className="menu-item">
                    <label className="setting-label">
                      <input 
                        type="checkbox" 
                        checked={showShadows}
                        onChange={() => setShowShadows(!showShadows)}
                      />
                      Show Shadows
                    </label>
                  </div>
                  <div className="menu-item">
                    <label className="setting-label">
                      <input 
                        type="checkbox" 
                        checked={enableOcclusionCulling}
                        onChange={() => setEnableOcclusionCulling(!enableOcclusionCulling)}
                      />
                      Enable Occlusion Culling
                    </label>
                  </div>
                  <div className="menu-item">
                    <label className="setting-label">
                      <input 
                        type="checkbox" 
                        checked={performanceMode}
                        onChange={() => setPerformanceMode(!performanceMode)}
                      />
                      Performance Mode
                    </label>
                  </div>
                  <div className="menu-item">
                    <label className="setting-label">
                      <input 
                        type="checkbox" 
                        checked={memoryOptimization}
                        onChange={() => setMemoryOptimization(!memoryOptimization)}
                      />
                      Memory Optimization (reduces memory usage)
                    </label>
                  </div>
                  <div className="menu-item">
                    <label className="setting-label">Lighting Mode</label>
                    <select 
                      value={lightingMode}
                      onChange={(e) => setLightingMode(e.target.value as 'day' | 'sunset' | 'night')}
                      className="setting-select"
                    >
                      <option value="day">Day</option>
                      <option value="sunset">Sunset</option>
                      <option value="night">Night</option>
                    </select>
                  </div>
                  <div className="menu-item">
                    <label className="setting-label">Render Distance</label>
                    <div className="slider-container">
                      <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        value={renderDistance}
                        onChange={(e) => setRenderDistance(parseInt(e.target.value))}
                        className="setting-slider"
                      />
                      <span className="slider-value">{renderDistance}</span>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Debug Menu */}
              {activePane === 'debug' && (
                <div className="menu-pane">
                  <h3>Debug Info</h3>
                  <div className="debug-stat">
                    <span className="debug-label">FPS:</span>
                    <span className="debug-value">{fps}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Visible Items:</span>
                    <span className="debug-value">{visibleItems.size}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Total Items:</span>
                    <span className="debug-value">{totalItems}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Occluded Blocks:</span>
                    <span className="debug-value">{enableOcclusionCulling ? occludedBlocks : 'Disabled'}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Frustum Culled Blocks:</span>
                    <span className="debug-value">{frustumCulledBlocks}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Culling Efficiency:</span>
                    <span className="debug-value">
                      {totalItems > 0 ? `${Math.round((totalItems - visibleItems.size) / totalItems * 100)}%` : '0%'}
                    </span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Memory Usage:</span>
                    <span className="debug-value">
                      {Math.round((performance as any).memory?.usedJSHeapSize / (1024 * 1024) || 0)} MB
                    </span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Render Distance:</span>
                    <span className="debug-value">{renderDistance}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Debug Controls:</span>
                    <span className="debug-value">Press F3 to toggle culling visualization</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Shadows:</span>
                    <span className="debug-value">{showShadows ? 'Enabled' : 'Disabled'}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Lighting:</span>
                    <span className="debug-value">{lightingMode}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Performance Mode:</span>
                    <span className="debug-value">{performanceMode ? 'Enabled' : 'Disabled'}</span>
                  </div>
                  <div className="debug-stat">
                    <span className="debug-label">Memory Optimization:</span>
                    <span className="debug-value">{memoryOptimization ? 'Enabled' : 'Disabled'}</span>
                  </div>
                  {instanceLimitWarning && (
                    <div className="debug-stat warning">
                      <span className="debug-label">Warning:</span>
                      <span className="debug-value">{instanceLimitWarning}</span>
                    </div>
                  )}
                </div>
              )}
              
              {/* Close button at the bottom of the menu */}
              <div className="menu-close-container">
                <button 
                  className="menu-close-button"
                  onClick={closeMenu}
                >
                  Close Menu
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Build button and block selector - outside the menu for better touch handling */}
      <BuildButton />
      <BlockSelector />
    </div>
  );
};

export default UI; 