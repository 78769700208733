import React from 'react';
import { Sphere } from '@react-three/drei';

const SimpleBush: React.FC = () => {
  return (
    <group position={[0, -0.5, 0]}>
      <Sphere args={[0.5, 8, 8]} position={[0, 0.5, 0]}>
        <meshStandardMaterial color="#2D5A27" />
      </Sphere>
      <Sphere args={[0.4, 8, 8]} position={[0.3, 0.7, 0]}>
        <meshStandardMaterial color="#2D5A27" />
      </Sphere>
      <Sphere args={[0.4, 8, 8]} position={[-0.3, 0.6, 0]}>
        <meshStandardMaterial color="#2D5A27" />
      </Sphere>
      <Sphere args={[0.3, 8, 8]} position={[0, 0.9, 0]}>
        <meshStandardMaterial color="#2D5A27" />
      </Sphere>
    </group>
  );
};

export default SimpleBush; 