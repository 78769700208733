import React from 'react';

const LogCabin: React.FC = () => {
  // Log colors
  const logColor = "#a0522d"; // Sienna
  const darkLogColor = "#654321"; // Dark brown
  const roofColor = "#8b4513"; // Saddle brown

  return (
    <group position={[0, -0.25, 0]}>
      {/* Cabin foundation/platform */}
      <mesh position={[0, 0.05, 0]} castShadow receiveShadow>
        <boxGeometry args={[2.2, 0.1, 1.8]} />
        <meshStandardMaterial color="#5d4037" />
      </mesh>

      {/* Main cabin base/floor */}
      <mesh position={[0, 0.2, 0]} castShadow receiveShadow>
        <boxGeometry args={[2, 0.2, 1.6]} />
        <meshStandardMaterial color="#8d6e63" />
      </mesh>

      {/* Log wall details - horizontal logs */}
      {[0.4, 0.7, 1.0, 1.3].map((height, i) => (
        <group key={`logs-${i}`}>
          {/* Front and back logs (longer) */}
          <mesh position={[0, height, 0.75]} castShadow receiveShadow>
            <boxGeometry args={[1.9, 0.15, 0.2]} />
            <meshStandardMaterial color={logColor} roughness={0.9} />
          </mesh>
          <mesh position={[0, height, -0.75]} castShadow receiveShadow>
            <boxGeometry args={[1.9, 0.15, 0.2]} />
            <meshStandardMaterial color={logColor} roughness={0.9} />
          </mesh>
          
          {/* Side logs (shorter) */}
          <mesh position={[0.95, height, 0]} castShadow receiveShadow>
            <boxGeometry args={[0.2, 0.15, 1.7]} />
            <meshStandardMaterial color={logColor} roughness={0.9} />
          </mesh>
          <mesh position={[-0.95, height, 0]} castShadow receiveShadow>
            <boxGeometry args={[0.2, 0.15, 1.7]} />
            <meshStandardMaterial color={logColor} roughness={0.9} />
          </mesh>
        </group>
      ))}

      {/* Log corners - vertical posts */}
      {[
        [0.95, 0.7, 0.75] as [number, number, number],
        [0.95, 0.7, -0.75] as [number, number, number],
        [-0.95, 0.7, 0.75] as [number, number, number],
        [-0.95, 0.7, -0.75] as [number, number, number]
      ].map((pos, i) => (
        <mesh key={`corner-${i}`} position={pos} castShadow receiveShadow>
          <boxGeometry args={[0.25, 1.2, 0.25]} />
          <meshStandardMaterial color={darkLogColor} roughness={0.9} />
        </mesh>
      ))}

      {/* Pitched roof */}
      <group position={[0, 1.5, 0]}>
        {/* Left roof panel */}
        <mesh position={[-0.5, 0.3, 0]} rotation={[0, 0, Math.PI / 6]} castShadow receiveShadow>
          <boxGeometry args={[1.2, 0.1, 1.8]} />
          <meshStandardMaterial color={roofColor} roughness={0.8} />
        </mesh>
        
        {/* Right roof panel */}
        <mesh position={[0.5, 0.3, 0]} rotation={[0, 0, -Math.PI / 6]} castShadow receiveShadow>
          <boxGeometry args={[1.2, 0.1, 1.8]} />
          <meshStandardMaterial color={roofColor} roughness={0.8} />
        </mesh>
        
        {/* Roof ridge */}
        <mesh position={[0, 0.6, 0]} castShadow receiveShadow>
          <boxGeometry args={[0.15, 0.1, 1.9]} />
          <meshStandardMaterial color="#3e2723" roughness={0.7} />
        </mesh>
      </group>

      {/* Door */}
      <mesh position={[0, 0.8, 0.85]} castShadow receiveShadow>
        <boxGeometry args={[0.6, 1.2, 0.05]} />
        <meshStandardMaterial color="#5d4037" roughness={0.8} />
      </mesh>
      
      {/* Door handle */}
      <mesh position={[0.2, 0.8, 0.88]} castShadow receiveShadow>
        <boxGeometry args={[0.08, 0.08, 0.05]} />
        <meshStandardMaterial color="#fdd835" metalness={0.8} roughness={0.2} />
      </mesh>

      {/* Windows */}
      <mesh position={[-0.6, 0.9, 0.85]} castShadow receiveShadow>
        <boxGeometry args={[0.4, 0.4, 0.05]} />
        <meshStandardMaterial color="#bbdefb" opacity={0.7} transparent={true} />
      </mesh>
      
      <mesh position={[0.6, 0.9, -0.85]} castShadow receiveShadow>
        <boxGeometry args={[0.4, 0.4, 0.05]} />
        <meshStandardMaterial color="#bbdefb" opacity={0.7} transparent={true} />
      </mesh>

      {/* Window frames */}
      <mesh position={[-0.6, 0.9, 0.86]} castShadow receiveShadow>
        <boxGeometry args={[0.45, 0.05, 0.03]} />
        <meshStandardMaterial color="#3e2723" />
      </mesh>
      <mesh position={[-0.6, 0.9, 0.86]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.45, 0.03]} />
        <meshStandardMaterial color="#3e2723" />
      </mesh>
      
      <mesh position={[0.6, 0.9, -0.86]} castShadow receiveShadow>
        <boxGeometry args={[0.45, 0.05, 0.03]} />
        <meshStandardMaterial color="#3e2723" />
      </mesh>
      <mesh position={[0.6, 0.9, -0.86]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.45, 0.03]} />
        <meshStandardMaterial color="#3e2723" />
      </mesh>

      {/* Chimney */}
      <mesh position={[-0.7, 1.8, 0.2]} castShadow receiveShadow>
        <boxGeometry args={[0.3, 0.6, 0.3]} />
        <meshStandardMaterial color="#546e7a" roughness={0.9} />
      </mesh>
      <mesh position={[-0.7, 2.15, 0.2]} castShadow receiveShadow>
        <boxGeometry args={[0.35, 0.1, 0.35]} />
        <meshStandardMaterial color="#37474f" roughness={0.9} />
      </mesh>
    </group>
  );
};

export default LogCabin; 