import React from 'react';
import { useGameStore } from '../../store/gameStore';
import { ItemType, ItemCategory } from '../../types';
import { 
  getAllCategories, 
  getCategoryInfo, 
  getItemsByCategory,
  getItemColor,
  getItemName,
  getPropThumbnail
} from '../../config/blockConfig';
import './BlockSelector.css';

const BlockSelector: React.FC = () => {
  const selectedItemType = useGameStore(state => state.selectedItemType);
  const selectedCategory = useGameStore(state => state.selectedCategory);
  const setSelectedItemType = useGameStore(state => state.setSelectedItemType);
  const setSelectedCategory = useGameStore(state => state.setSelectedCategory);
  const buildMode = useGameStore(state => state.buildMode);
  const toggleBuildMode = useGameStore(state => state.toggleBuildMode);
  
  // Don't render anything if not in build mode
  if (!buildMode) return null;
  
  const categories = getAllCategories();
  
  const handleCloseClick = () => {
    console.log('Closing build mode');
    toggleBuildMode();
  };
  
  const renderContent = () => {
    const items = getItemsByCategory(selectedCategory);
    
    if (selectedCategory === 'nature' || selectedCategory === 'buildings') {
      return (
        <div className="block-selector">
          {items.map((type: ItemType) => (
            <div
              key={type}
              className={`block-option-container ${selectedItemType === type ? 'selected' : ''}`}
              onClick={() => setSelectedItemType(type)}
            >
              <div className="block-option prop-option">
                <span className="prop-emoji">{getPropThumbnail(type)}</span>
              </div>
              <span className="block-label">
                {getItemName(type)}
              </span>
            </div>
          ))}
        </div>
      );
    }
    
    return (
      <div className="block-selector">
        {items.map((type: ItemType) => (
          <div
            key={type}
            className={`block-option-container ${selectedItemType === type ? 'selected' : ''}`}
            onClick={() => setSelectedItemType(type)}
          >
            <div 
              className="block-option"
              style={{ backgroundColor: getItemColor(type) }}
            />
            <span className="block-label">
              {getItemName(type)}
            </span>
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <div className="block-selector-wrapper">
      <div className="category-tabs">
        {categories.map((category: ItemCategory) => {
          const categoryInfo = getCategoryInfo(category);
          return (
            <button
              key={category}
              className={`category-tab ${selectedCategory === category ? 'selected' : ''}`}
              onClick={() => setSelectedCategory(category)}
              title={categoryInfo.name}
            >
              <span className="category-icon">{categoryInfo.icon}</span>
              <span className="category-label">{categoryInfo.name}</span>
            </button>
          );
        })}
        <button
          className="category-tab close-build-mode"
          onClick={handleCloseClick}
          title="Exit Build Mode"
        >
          <span className="category-icon">✖</span>
          <span className="category-label">Close</span>
        </button>
      </div>
      
      {renderContent()}
    </div>
  );
};

export default BlockSelector; 