import React from 'react';

const WoodenFence: React.FC = () => {
  // Colors
  const woodColor = "#8B4513"; // SaddleBrown
  const darkWoodColor = "#654321"; // Dark brown

  return (
    <group position={[0, -0.2, 0]}>
      {/* Vertical posts */}
      <mesh position={[-0.45, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.8, 0.1]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>
      <mesh position={[0.45, 0.4, 0]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.8, 0.1]} />
        <meshStandardMaterial color={woodColor} />
      </mesh>

      {/* Horizontal beams */}
      <mesh position={[0, 0.2, 0]} castShadow receiveShadow>
        <boxGeometry args={[1, 0.08, 0.08]} />
        <meshStandardMaterial color={darkWoodColor} />
      </mesh>
      <mesh position={[0, 0.6, 0]} castShadow receiveShadow>
        <boxGeometry args={[1, 0.08, 0.08]} />
        <meshStandardMaterial color={darkWoodColor} />
      </mesh>

      {/* Vertical planks */}
      {[-0.35, -0.175, 0, 0.175, 0.35].map((x, i) => (
        <mesh key={i} position={[x, 0.4, 0]} castShadow receiveShadow>
          <boxGeometry args={[0.15, 0.7, 0.04]} />
          <meshStandardMaterial color="#deb887" />
        </mesh>
      ))}
    </group>
  );
};

export default WoodenFence; 