import React from 'react';
import { Icosahedron, Dodecahedron } from '@react-three/drei';

const SimpleRock: React.FC = () => {
  return (
    <group position={[0, -0.5, 0]}>
      {/* Main rock body */}
      <Dodecahedron args={[0.5, 0]} position={[0, 0.5, 0]} rotation={[0.4, 0.7, 0.2]}>
        <meshStandardMaterial 
          color="#808080" 
          roughness={0.9}
          metalness={0.1}
        />
      </Dodecahedron>

      {/* Additional rock features for irregular shape */}
      <Icosahedron 
        args={[0.35, 0]} 
        position={[0.2, 0.35, 0.2]} 
        rotation={[0.5, 0.2, 0.8]}
        scale={[1.2, 0.8, 1]}
      >
        <meshStandardMaterial 
          color="#707070" 
          roughness={0.85}
          metalness={0.15}
        />
      </Icosahedron>

      <Dodecahedron 
        args={[0.3, 0]} 
        position={[-0.25, 0.3, -0.1]} 
        rotation={[-0.2, 0.5, -0.3]}
        scale={[1, 1.2, 0.9]}
      >
        <meshStandardMaterial 
          color="#757575" 
          roughness={0.87}
          metalness={0.12}
        />
      </Dodecahedron>

      {/* Small details */}
      <Icosahedron 
        args={[0.2, 0]} 
        position={[0.1, 0.4, -0.2]} 
        rotation={[0.3, -0.4, 0.1]}
        scale={[0.9, 1.1, 0.8]}
      >
        <meshStandardMaterial 
          color="#686868" 
          roughness={0.92}
          metalness={0.08}
        />
      </Icosahedron>
    </group>
  );
};

export default SimpleRock; 