import blocksConfig from './blocks.json';
import { ItemType, ItemCategory } from '../types';

export interface BlockConfig {
  name: string;
  color: string;
  stackable?: boolean; // Optional, since the JSON doesn't have this property yet
  rotatable?: boolean; // Whether this item can be rotated through the UI
}

export interface PropModelInfo {
  type: 'glb' | 'component';
  path: string;
}

export interface PropConfig {
  name: string;
  description: string;
  thumbnail: string;
  model: PropModelInfo;
  scale: number;
  baseHeight?: number; // Height offset to position item properly on the ground
  randomize_rotation?: boolean;
  stackable?: boolean; // Optional, since the JSON doesn't have this property yet
  rotatable?: boolean; // Whether this item can be rotated through the UI
}

export interface BlocksCategoryConfig {
  name: string;
  icon: string;
  blocks: Record<string, BlockConfig>;
}

export interface NatureCategoryConfig {
  name: string;
  icon: string;
  props: Record<string, PropConfig>;
}

export interface BuildingsCategoryConfig {
  name: string;
  icon: string;
  props: Record<string, PropConfig>;
}

export type CategoryConfig = {
  blocks: BlocksCategoryConfig;
  nature: NatureCategoryConfig;
  buildings: BuildingsCategoryConfig;
};

export type BlocksConfig = {
  categories: CategoryConfig;
};

// Type assertion for the imported JSON - use 'any' to bypass type checking
const config = blocksConfig as any as BlocksConfig;

// Default configurations for stackable property
// Blocks are stackable by default
const defaultBlocksStackable = true;
// Props are not stackable by default
const defaultPropsStackable = false;

// Get all item types from a category
export const getItemsByCategory = (category: ItemCategory): ItemType[] => {
  if (category === 'blocks') {
    return Object.keys(config.categories.blocks.blocks) as ItemType[];
  }
  if (category === 'nature') {
    return Object.keys(config.categories.nature.props) as ItemType[];
  }
  if (category === 'buildings') {
    return Object.keys(config.categories.buildings.props) as ItemType[];
  }
  return [];
};

// Get item color - applicable for blocks
export const getItemColor = (itemType: ItemType): string => {
  const blocks = config.categories.blocks.blocks;
  if (blocks[itemType]) {
    return blocks[itemType].color || '#ffffff';
  }
  
  // Default color for non-blocks
  return '#ffffff';
};

// Check if an item is stackable
export const isItemStackable = (itemType: ItemType): boolean => {
  // Check if it's a block
  const blocks = config.categories.blocks.blocks;
  if (blocks[itemType]) {
    return blocks[itemType].stackable === true || 
          (blocks[itemType].stackable === undefined && defaultBlocksStackable);
  }
  
  // Check if it's a nature prop
  const natureProps = config.categories.nature.props;
  if (natureProps[itemType]) {
    return natureProps[itemType].stackable === true || 
          (natureProps[itemType].stackable === undefined && defaultPropsStackable);
  }
  
  // Check if it's a building prop
  const buildingProps = config.categories.buildings.props;
  if (buildingProps[itemType]) {
    return buildingProps[itemType].stackable === true || 
          (buildingProps[itemType].stackable === undefined && defaultPropsStackable);
  }
  
  // Default fallback
  return false;
};

// Check if an item is rotatable
export const isItemRotatable = (itemType: ItemType): boolean => {
  // Check if it's a block
  const blocks = config.categories.blocks.blocks;
  if (blocks[itemType]) {
    return blocks[itemType].rotatable === true;
  }
  
  // Check if it's a nature prop
  const natureProps = config.categories.nature.props;
  if (natureProps[itemType]) {
    return natureProps[itemType].rotatable === true;
  }
  
  // Check if it's a building prop
  const buildingProps = config.categories.buildings.props;
  if (buildingProps[itemType]) {
    return buildingProps[itemType].rotatable === true;
  }
  
  // Default fallback
  return false;
};

// Get configuration for an item regardless of its category
export const getItemConfig = (itemType: ItemType): BlockConfig | PropConfig | null => {
  // Check if it's a block
  const blocks = config.categories.blocks.blocks;
  if (blocks[itemType]) {
    return blocks[itemType];
  }
  
  // Check if it's a nature prop
  const natureProps = config.categories.nature.props;
  if (natureProps[itemType]) {
    return natureProps[itemType];
  }
  
  // Check if it's a building prop
  const buildingProps = config.categories.buildings.props;
  if (buildingProps[itemType]) {
    return buildingProps[itemType];
  }
  
  return null;
};

// Get category info
export const getCategoryInfo = (category: ItemCategory) => {
  return config.categories[category];
};

// Get all categories
export const getAllCategories = (): ItemCategory[] => {
  return Object.keys(config.categories) as ItemCategory[];
};

// Get item name
export const getItemName = (itemType: ItemType): string => {
  const config = getItemConfig(itemType);
  return config?.name || itemType;
};

// Get prop description (only applicable for props)
export const getPropDescription = (propType: ItemType): string => {
  const natureProps = config.categories.nature.props;
  const buildingProps = config.categories.buildings.props;
  return natureProps[propType]?.description || buildingProps[propType]?.description || '';
};

// Get prop thumbnail (only applicable for props)
export const getPropThumbnail = (propType: ItemType): string => {
  const natureProps = config.categories.nature.props;
  const buildingProps = config.categories.buildings.props;
  return natureProps[propType]?.thumbnail || buildingProps[propType]?.thumbnail || '🌿';
};

// Get prop model info (only applicable for props)
export const getPropModelInfo = (propType: ItemType): PropModelInfo => {
  const natureProps = config.categories.nature.props;
  const buildingProps = config.categories.buildings.props;
  return natureProps[propType]?.model || buildingProps[propType]?.model || { type: 'component', path: 'SimpleTree' };
};

// Get prop scale (only applicable for props)
export const getPropScale = (propType: ItemType): number => {
  const natureProps = config.categories.nature.props;
  const buildingProps = config.categories.buildings.props;
  return natureProps[propType]?.scale || buildingProps[propType]?.scale || 1.0;
};

// Get prop randomize_rotation setting (only applicable for props)
export const getPropRandomizeRotation = (propType: ItemType): boolean => {
  const natureProps = config.categories.nature.props;
  const buildingProps = config.categories.buildings.props;
  return natureProps[propType]?.randomize_rotation || buildingProps[propType]?.randomize_rotation || false;
};

// Get prop base height (only applicable for props)
export const getPropBaseHeight = (propType: ItemType): number => {
  // Default base height
  const DEFAULT_HEIGHT = 0.5;
  
  // Check nature props
  const natureProp = config.categories.nature.props[propType];
  if (natureProp && natureProp.baseHeight !== undefined && natureProp.baseHeight !== null) {
    return Number(natureProp.baseHeight);
  }
  
  // Check building props
  const buildingProp = config.categories.buildings.props[propType];
  if (buildingProp && buildingProp.baseHeight !== undefined && buildingProp.baseHeight !== null) {
    return Number(buildingProp.baseHeight);
  }
  
  // Return default if no valid height found
  return DEFAULT_HEIGHT;
};

export default config; 