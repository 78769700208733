import React from 'react';

const Watchtower: React.FC = () => {
  // Colors
  const woodBaseColor = "#8B4513"; // SaddleBrown
  const woodLightColor = "#A0522D"; // Sienna
  const woodMidColor = "#954535"; // Medium brown
  const roofColor = "#654321"; // Dark brown

  return (
    <group position={[0, -0.25, 0]}>
      {/* Base platform with wood texture */}
      <mesh position={[0, 0.25, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.8, 0.4, 1.8]} />
        <meshStandardMaterial color={woodBaseColor} roughness={0.9} />
      </mesh>

      {/* Support beams at the corners */}
      {[
        [0.8, 1.5, 0.8] as [number, number, number],
        [0.8, 1.5, -0.8] as [number, number, number],
        [-0.8, 1.5, 0.8] as [number, number, number],
        [-0.8, 1.5, -0.8] as [number, number, number]
      ].map((pos, i) => (
        <mesh key={`beam-${i}`} position={pos} castShadow receiveShadow>
          <boxGeometry args={[0.2, 2.6, 0.2]} />
          <meshStandardMaterial color={woodBaseColor} roughness={0.9} />
        </mesh>
      ))}

      {/* Main tower structure - more slender and detailed */}
      <mesh position={[0, 1.8, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.5, 3.1, 1.5]} />
        <meshStandardMaterial color={woodMidColor} roughness={0.8} />
      </mesh>

      {/* Horizontal support bands around the tower */}
      {[0.6, 1.8, 3.0].map((height, i) => (
        <group key={`band-${i}`}>
          <mesh position={[0, height, 0]} castShadow receiveShadow>
            <boxGeometry args={[1.6, 0.15, 1.6]} />
            <meshStandardMaterial color={woodBaseColor} roughness={0.9} />
          </mesh>
        </group>
      ))}

      {/* Top platform - more detailed with visible planking */}
      <mesh position={[0, 3.5, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.9, 0.2, 1.9]} />
        <meshStandardMaterial color={woodLightColor} roughness={0.8} />
      </mesh>

      {/* Roof structure - more detailed and with better shaping */}
      <group position={[0, 4.2, 0]}>
        {/* Main roof cone */}
        <mesh castShadow receiveShadow>
          <coneGeometry args={[1.2, 1.2, 8]} />
          <meshStandardMaterial color={roofColor} roughness={0.8} />
        </mesh>
        
        {/* Roof cap */}
        <mesh position={[0, 0.7, 0]} castShadow receiveShadow>
          <cylinderGeometry args={[0.1, 0.2, 0.3, 8]} />
          <meshStandardMaterial color={woodBaseColor} roughness={0.7} />
        </mesh>
        
        {/* Roof finial/spire */}
        <mesh position={[0, 1, 0]} castShadow receiveShadow>
          <coneGeometry args={[0.05, 0.3, 8]} />
          <meshStandardMaterial color={woodBaseColor} roughness={0.7} />
        </mesh>
      </group>

      {/* Windows - with frames and better positioning */}
      {[0, Math.PI/2, Math.PI, -Math.PI/2].map((rotation, i) => (
        <group key={`window-${i}`} rotation={[0, rotation, 0]}>
          <mesh position={[0, 2.5, 0.76]} castShadow receiveShadow>
            <boxGeometry args={[0.4, 0.6, 0.05]} />
            <meshStandardMaterial color="#BBDEFB" opacity={0.7} transparent />
          </mesh>
          
          {/* Window frame */}
          <mesh position={[0, 2.5, 0.78]} castShadow receiveShadow>
            <boxGeometry args={[0.45, 0.05, 0.06]} />
            <meshStandardMaterial color={woodBaseColor} />
          </mesh>
          <mesh position={[0, 2.5, 0.78]} castShadow receiveShadow>
            <boxGeometry args={[0.05, 0.65, 0.06]} />
            <meshStandardMaterial color={woodBaseColor} />
          </mesh>
        </group>
      ))}

      {/* Door with frame */}
      <group>
        <mesh position={[0, 1, 0.76]} castShadow receiveShadow>
          <boxGeometry args={[0.5, 1.2, 0.05]} />
          <meshStandardMaterial color={woodBaseColor} roughness={0.8} />
        </mesh>
        
        {/* Door frame */}
        <mesh position={[0, 1, 0.77]} castShadow receiveShadow>
          <boxGeometry args={[0.6, 0.05, 0.07]} />
          <meshStandardMaterial color={woodLightColor} />
        </mesh>
        <mesh position={[0.3, 1, 0.77]} castShadow receiveShadow>
          <boxGeometry args={[0.05, 1.3, 0.07]} />
          <meshStandardMaterial color={woodLightColor} />
        </mesh>
        <mesh position={[-0.3, 1, 0.77]} castShadow receiveShadow>
          <boxGeometry args={[0.05, 1.3, 0.07]} />
          <meshStandardMaterial color={woodLightColor} />
        </mesh>
        
        {/* Door handle */}
        <mesh position={[0.2, 1, 0.79]} castShadow receiveShadow>
          <boxGeometry args={[0.06, 0.06, 0.06]} />
          <meshStandardMaterial color="#A1887F" metalness={0.6} roughness={0.4} />
        </mesh>
      </group>

      {/* Platform railings - more detailed with posts and rails */}
      {[0, Math.PI/2, Math.PI, -Math.PI/2].map((rotation, i) => (
        <group key={`railing-${i}`} rotation={[0, rotation, 0]}>
          {/* Railing posts */}
          <mesh position={[0.7, 3.85, 0.95]} castShadow receiveShadow>
            <boxGeometry args={[0.08, 0.7, 0.08]} />
            <meshStandardMaterial color={woodBaseColor} />
          </mesh>
          <mesh position={[0, 3.85, 0.95]} castShadow receiveShadow>
            <boxGeometry args={[0.08, 0.7, 0.08]} />
            <meshStandardMaterial color={woodBaseColor} />
          </mesh>
          <mesh position={[-0.7, 3.85, 0.95]} castShadow receiveShadow>
            <boxGeometry args={[0.08, 0.7, 0.08]} />
            <meshStandardMaterial color={woodBaseColor} />
          </mesh>
          
          {/* Horizontal rails */}
          <mesh position={[0, 3.6, 0.95]} castShadow receiveShadow>
            <boxGeometry args={[1.48, 0.06, 0.06]} />
            <meshStandardMaterial color={woodLightColor} />
          </mesh>
          <mesh position={[0, 4.1, 0.95]} castShadow receiveShadow>
            <boxGeometry args={[1.48, 0.06, 0.06]} />
            <meshStandardMaterial color={woodLightColor} />
          </mesh>
        </group>
      ))}

      {/* Ladder on one side */}
      <group rotation={[0, 0, 0]}>
        {/* Ladder rails */}
        <mesh position={[0.25, 1.2, 0.78]} castShadow receiveShadow>
          <boxGeometry args={[0.05, 2.1, 0.05]} />
          <meshStandardMaterial color={woodMidColor} />
        </mesh>
        <mesh position={[-0.25, 1.2, 0.78]} castShadow receiveShadow>
          <boxGeometry args={[0.05, 2.1, 0.05]} />
          <meshStandardMaterial color={woodMidColor} />
        </mesh>
        
        {/* Ladder rungs */}
        {[0.5, 0.8, 1.1, 1.4, 1.7, 2.0].map((height, i) => (
          <mesh key={`rung-${i}`} position={[0, height, 0.78]} castShadow receiveShadow>
            <boxGeometry args={[0.45, 0.05, 0.05]} />
            <meshStandardMaterial color={woodMidColor} />
          </mesh>
        ))}
      </group>
    </group>
  );
};

export default Watchtower; 