import React, { useState } from 'react';
import { useGameStore } from '../../store/gameStore';
import { WorldGenerationOptions, ItemType } from '../../types';
import { getItemsByCategory } from '../../config/blockConfig';
import './WorldGenerationMenu.css';

interface WorldGenerationMenuProps {
  onClose: () => void;
}

const WorldGenerationMenu: React.FC<WorldGenerationMenuProps> = ({ onClose }) => {
  // Get world generation function from the store
  const generateWorld = useGameStore(state => state.generateWorld);
  const isGeneratingWorld = useGameStore(state => state.isGeneratingWorld);
  
  // State for form values
  const [worldType, setWorldType] = useState<'flat' | 'random'>('random');
  const [worldSize, setWorldSize] = useState<number>(15);
  const [includeProps, setIncludeProps] = useState<boolean>(true);
  const [waterLevel, setWaterLevel] = useState<number>(2);
  const [terrainIntensity, setTerrainIntensity] = useState<number>(5);
  const [blockType, setBlockType] = useState<ItemType>('grass');
  
  // Get block types for the flat world selector
  const blockItems = getItemsByCategory('blocks');
  
  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Create options object
    const options: WorldGenerationOptions = {
      type: worldType,
      size: worldSize,
      includeProps,
      waterLevel,
      terrainIntensity,
      blockType
    };
    
    // Generate the world
    generateWorld(options);
    
    // Close the menu
    onClose();
  };
  
  return (
    <div className="world-generation-menu-overlay">
      <div className="world-generation-menu">
        <h2>World Generation</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>World Type</label>
            <div className="radio-options">
              <label>
                <input
                  type="radio"
                  name="worldType"
                  value="flat"
                  checked={worldType === 'flat'}
                  onChange={() => setWorldType('flat')}
                />
                Flat World
              </label>
              <label>
                <input
                  type="radio"
                  name="worldType"
                  value="random"
                  checked={worldType === 'random'}
                  onChange={() => setWorldType('random')}
                />
                Random World
              </label>
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="worldSize">World Size (Radius)</label>
            <div className="slider-with-value">
              <input
                type="range"
                id="worldSize"
                min="5"
                max="30"
                value={worldSize}
                onChange={(e) => setWorldSize(parseInt(e.target.value))}
              />
              <span className="slider-value">{worldSize}</span>
            </div>
          </div>
          
          {worldType === 'flat' && (
            <div className="form-group">
              <label htmlFor="blockType">Block Type</label>
              <select
                id="blockType"
                value={blockType}
                onChange={(e) => setBlockType(e.target.value as ItemType)}
              >
                {blockItems.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          )}
          
          {worldType === 'random' && (
            <>
              <div className="form-group">
                <label htmlFor="waterLevel">Water Level</label>
                <div className="slider-with-value">
                  <input
                    type="range"
                    id="waterLevel"
                    min="0"
                    max="5"
                    value={waterLevel}
                    onChange={(e) => setWaterLevel(parseInt(e.target.value))}
                  />
                  <span className="slider-value">{waterLevel}</span>
                </div>
              </div>
              
              <div className="form-group">
                <label htmlFor="terrainIntensity">Terrain Intensity</label>
                <div className="slider-with-value">
                  <input
                    type="range"
                    id="terrainIntensity"
                    min="0"
                    max="10"
                    value={terrainIntensity}
                    onChange={(e) => setTerrainIntensity(parseInt(e.target.value))}
                  />
                  <span className="slider-value">{terrainIntensity}</span>
                </div>
                <div className="slider-description">
                  {terrainIntensity === 0 ? 'Completely flat terrain' :
                   terrainIntensity < 4 ? 'Gentle rolling hills' :
                   terrainIntensity < 7 ? 'Moderate hills and valleys' :
                   'Dramatic mountains and deep valleys'}
                </div>
              </div>
              
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={includeProps}
                    onChange={(e) => setIncludeProps(e.target.checked)}
                  />
                  Include Props (Trees, Rocks, Buildings)
                </label>
              </div>
            </>
          )}
          
          <div className="form-actions">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="generate-button" disabled={isGeneratingWorld}>
              {isGeneratingWorld ? 'Generating...' : 'Generate World'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WorldGenerationMenu; 