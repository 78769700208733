import React from 'react';

const StoneWall: React.FC = () => {
  // Colors
  const stoneColor = "#808080"; // Gray
  const darkStoneColor = "#505050"; // Dark gray

  // This offset moves the wall to the -Z edge of the 1x1 unit
  const zOffset = -0.4; // Position at -Z edge (wall thickness is 0.8, so center is at -0.4)
  
  // Height constants to ensure total height is exactly 1 unit
  const baseHeight = 0.3;
  const mainHeight = 0.4; 
  const topHeight = 0.3;
  // Total height = 0.3 + 0.4 + 0.3 = 1.0 unit

  // Set a small Y-offset to fix floating issue - ensures the bottom of the wall is exactly at y=0
  const yOffset = -0.05; // Apply a small downward shift to fix the floating issue

  return (
    <group>
      {/* Base - positioned directly on the ground */}
      <mesh position={[0, baseHeight/2 + yOffset, zOffset]} castShadow receiveShadow>
        <boxGeometry args={[1, baseHeight, 0.2]} />
        <meshStandardMaterial color={darkStoneColor} />
      </mesh>

      {/* Main wall - positioned above the base */}
      <mesh position={[0, baseHeight + mainHeight/2 + yOffset, zOffset]} castShadow receiveShadow>
        <boxGeometry args={[1, mainHeight, 0.2]} />
        <meshStandardMaterial color={stoneColor} />
      </mesh>

      {/* Top details - positioned above the main wall */}
      <mesh position={[0, baseHeight + mainHeight + topHeight/2 + yOffset, zOffset]} castShadow receiveShadow>
        <boxGeometry args={[1.1, topHeight, 0.2]} />
        <meshStandardMaterial color={darkStoneColor} />
      </mesh>

      {/* Decorative stones - positioned on +Z side (front of wall) */}
      {[-0.35, 0, 0.35].map((x, i) => (
        <mesh key={i} position={[x, baseHeight + mainHeight + topHeight/2 + yOffset, zOffset + 0.15]} castShadow receiveShadow>
          <boxGeometry args={[0.3, 0.25, 0.1]} />
          <meshStandardMaterial color={darkStoneColor} />
        </mesh>
      ))}
    </group>
  );
};

export default StoneWall; 