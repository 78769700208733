import { create } from 'zustand';
import { ItemType, ItemCategory, GameState, Item, PlacementPreview, WorldGenerationOptions } from '../types';
import { getAllCategories, getItemsByCategory, getPropRandomizeRotation, isItemStackable, isItemRotatable } from '../config/blockConfig';
import { v4 as uuidv4 } from 'uuid';
import { createNoise2D } from 'simplex-noise';
import { adjustCamera } from '../App';

const generateInitialItems = () => {
  const items: Record<string, Item> = {};
  const categories = getAllCategories();
  const firstCategory = categories[0];
  const availableItems = getItemsByCategory(firstCategory);
  
  // Create base floor blocks
  for (let x = -15; x <= 15; x++) {
    for (let z = -15; z <= 15; z++) {
      const id = uuidv4();
      const type = availableItems[0];
      items[id] = {
        id,
        type,
        position: { x, y: 0, z },
        rotation: 0,
        scale: 1.0,
        stackable: true, // All initial blocks are stackable
        rotatable: false // Initial blocks aren't rotatable
      };
    }
  }
  
  return items;
};

export const useGameStore = create<GameState>((set) => ({
  // Unified item state
  items: generateInitialItems(),
  selectedItemType: getItemsByCategory(getAllCategories()[0])[0],
  visibleItems: new Set(),
  
  // Shared state
  selectedCategory: getAllCategories()[0],
  
  // Placement preview state
  placementPreview: null,
  
  // Build mode - start with build mode off
  buildMode: false,
  
  // Debug mode - start with debug mode off
  debugMode: false,
  
  // Loading state
  isGeneratingWorld: false,
  generationProgress: 0,
  
  // Item actions
  setSelectedItemType: (itemType: ItemType) => 
    set({ selectedItemType: itemType }),
    
  // Category actions
  setSelectedCategory: (category: ItemCategory) => 
    set((state) => ({
      selectedCategory: category,
      // Reset selection based on category
      selectedItemType: getItemsByCategory(category)[0]
    })),
  
  // Item manipulation
  addItem: (x: number, y: number, z: number, type: ItemType) =>
    set((state) => {
      // Only allow adding items if in build mode
      if (!state.buildMode) return state;
      
      const id = uuidv4();
      const shouldRandomizeRotation = getPropRandomizeRotation(type);
      const stackable = isItemStackable(type);
      const rotatable = isItemRotatable(type);
      
      return {
        items: {
          ...state.items,
          [id]: {
            id,
            type,
            position: { x, y, z },
            rotation: shouldRandomizeRotation ? Math.random() * Math.PI * 2 : 0,
            scale: 1.0,
            stackable,
            rotatable
          }
        },
        // Clear the placement preview when adding an item
        placementPreview: null
      };
    }),
    
  removeItem: (id: string) =>
    set((state) => {
      const newItems = { ...state.items };
      delete newItems[id];
      return { items: newItems };
    }),
    
  rotateItem: (id: string) =>
    set((state) => {
      // If item doesn't exist, do nothing
      if (!state.items[id]) return state;
      
      // Get current rotation and add 90 degrees (PI/2 radians)
      const currentRotation = state.items[id].rotation;
      const newRotation = (currentRotation + Math.PI / 2) % (Math.PI * 2);
      
      // Return updated state with rotated item
      return {
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            rotation: newRotation
          }
        }
      };
    }),
  
  // Visibility management
  setVisibleItems: (items: Set<string>) => 
    set({ visibleItems: items }),
  
  // World management
  reset: () => set({
    items: generateInitialItems(),
    selectedItemType: getItemsByCategory(getAllCategories()[0])[0],
    selectedCategory: getAllCategories()[0],
    visibleItems: new Set()
  }),
  
  // Generate a world based on provided options
  generateWorld: (options: WorldGenerationOptions) => {
    // Set loading state to true and reset progress
    set({ 
      isGeneratingWorld: true,
      generationProgress: 0
    });
    
    // Use requestAnimationFrame to ensure the UI updates with the loading state
    requestAnimationFrame(() => {
      // Reset current world
      const newItems: Record<string, Item> = {};
      
      const size = options.size || 30;
      const includeProps = options.includeProps !== undefined ? options.includeProps : true;
      const waterLevel = options.waterLevel !== undefined ? options.waterLevel : 2;
      const terrainIntensity = options.terrainIntensity !== undefined ? options.terrainIntensity : 5;
      
      // Process based on world type
      if (options.type === 'flat') {
        // Generate a flat world
        const generateFlatWorld = () => {
          const worldRadius = size;
          const blockType = options.blockType || 'grass';
          
          // Generate a single layer of blocks
          for (let x = -worldRadius; x <= worldRadius; x++) {
            for (let z = -worldRadius; z <= worldRadius; z++) {
              // Only add blocks within the circular world boundary
              if (Math.sqrt(x * x + z * z) <= worldRadius) {
                const id = uuidv4();
                newItems[id] = {
                  id,
                  type: blockType,
                  position: { x, y: 0, z },
                  rotation: 0,
                  scale: 1.0,
                  stackable: true,
                  rotatable: false
                };
              }
            }
          }
          
          // Update progress to 100%
          set({ 
            generationProgress: 100,
            items: newItems, 
            isGeneratingWorld: false 
          });
          
          // Adjust camera based on world size
          adjustCamera(worldRadius);
        };
        
        // Execute with small delay to ensure UI updates
        setTimeout(generateFlatWorld, 100);
      } else {
        // Generate a random world
        const generateRandomWorldChunked = () => {
          // World generation parameters
          const worldSize = size * 2 + 1; // Convert radius to diameter + 1 for center
          const worldRadius = size;
          
          // Biome types
          type BiomeType = 'mountains' | 'forest' | 'plains' | 'desert' | 'hills';
          
          // Initialize noise generators for terrain
          const noise2D = createNoise2D();
          
          // Initialize the heightmap
          const heightmap: Record<string, { height: number, biome: BiomeType }> = {};
          
          // Initialize river paths
          const riverPaths: Array<Array<{x: number, z: number}>> = [];
          
          // Steps for chunked generation to keep UI responsive
          let currentStep = 0;
          
          // Define all the generation steps
          const steps = [
            // Step 1: Generate heightmap
            () => { generateHeightmap(-worldRadius, worldRadius); },
            
            // Step 2: Generate rivers
            () => { generateRivers(); },
            
            // Step 3: Generate lakes
            () => { generateLakes(); },
            
            // Step 4: Generate terrain
            () => { generateTerrain(-worldRadius, worldRadius); },
          ];
          
          // If we're including props, add them to the steps
          if (includeProps) {
            steps.push(
              // Step 5: Generate terrain features
              () => {
                terrainFeatures.forEach(feature => {
                  placeFeatures(feature);
                });
              }
            );
          }
          
          // Store potential river sources
          const potentialRiverSources: Array<{x: number, z: number}> = [];
          
          // Step 1: Generate heightmap
          const generateHeightmap = (x1: number, x2: number) => {
            // Calculate terrain features like continents, mountains, hills
            for (let x = x1; x <= x2; x++) {
              for (let z = -worldRadius; z <= worldRadius; z++) {
                // Only process cells within the world radius
                if (Math.sqrt(x * x + z * z) > worldRadius) continue;
                
                let height: number;
                let biome: BiomeType;
                
                // Special case: If terrain intensity is 0, create completely flat terrain
                if (terrainIntensity === 0) {
                  // Create a flat terrain with a consistent height
                  height = waterLevel + 1; // Just above water level
                  biome = 'plains'; // Default to plains biome for flat terrain
                } else {
                  // Calculate an intensity factor (0.2 to 1.5) based on terrainIntensity (1-10)
                  // Make values 1-3 produce much milder terrain
                  const intensityFactor = terrainIntensity < 4 
                    ? 0.1 + (terrainIntensity / 20) // 0.15 to 0.25 for values 1-3
                    : 0.3 + (terrainIntensity / 10); // 0.7 to 1.3 for values 4-10
                  
                  // Base terrain with continentality
                  // Use lower frequency for continent-like structures
                  const continentNoise = noise2D(x * 0.01, z * 0.01) * 0.5 + 0.5;
                  
                  // Make terrain lower near edges (ocean)
                  const distanceFromCenter = Math.sqrt(x * x + z * z);
                  const edgeFactor = Math.max(0, 1 - Math.pow(distanceFromCenter / worldRadius, 1.5));
                  
                  // Base elevation influenced by continentality and edge distance
                  let baseElevation = (continentNoise * 0.7 + 0.3) * edgeFactor;
                  
                  // Add medium-scale terrain features - apply intensity factor
                  const terrain1 = noise2D(x * 0.03, z * 0.03) * 0.15 * intensityFactor;
                  const terrain2 = noise2D(x * 0.06, z * 0.06) * 0.1 * intensityFactor;
                  
                  // Mountains - use noise to determine mountain regions
                  // Apply higher intensity for mountains when terrain intensity is high
                  const mountainIntensity = Math.pow(intensityFactor, 1.5);
                  const mountainMask = Math.pow(noise2D(x * 0.02 + 500, z * 0.02 + 500) * 0.5 + 0.5, 2.0);
                  const mountains = Math.pow(noise2D(x * 0.04 + 1000, z * 0.04 + 1000) * 0.5 + 0.5, 3.0) * mountainMask * 2.0 * mountainIntensity;
                  
                  // Hills - use different noise component - apply intensity
                  const hills = Math.pow(noise2D(x * 0.08 + 2000, z * 0.08 + 2000) * 0.5 + 0.5, 1.5) * 0.8 * intensityFactor;
                  
                  // Small terrain details - apply intensity
                  const details = noise2D(x * 0.15, z * 0.15) * 0.05 * intensityFactor;
                  
                  // Combine all elevation components
                  let elevation = baseElevation + terrain1 + terrain2 + mountains + hills + details;
                  
                  // Convert to height range appropriate for the game (0-15)
                  const rawHeight = elevation * 14; // 0-14 range
                  height = Math.round(Math.max(0, rawHeight));
                  
                  // Make sure underwater terrain varies too (for lake/ocean beds)
                  if (height <= waterLevel) {
                    // Underwater terrain varies less but still has features
                    const underwaterTerrain = noise2D(x * 0.1, z * 0.1) * 0.5 + 0.5;
                    height = Math.max(0, Math.round(waterLevel * underwaterTerrain * 0.8));
                  }
                  
                  // Climate and moisture for biomes
                  const temperature = noise2D(x * 0.03 + 3000, z * 0.03 + 3000) * 0.5 + 0.5;
                  const moisture = noise2D(x * 0.04 + 4000, z * 0.04 + 4000) * 0.5 + 0.5;
                  
                  // Determine biome based on elevation, temperature and moisture
                  if (height > 10) {
                    // High mountains
                    biome = 'mountains';
                  } else if (height > 7) {
                    // Mountain/hill transition based on moisture
                    biome = moisture < 0.4 ? 'mountains' : 'hills';
                  } else if (height > 5) {
                    // Hills
                    biome = 'hills';
                  } else if (height > waterLevel) {
                    // Regular terrain - use moisture and temperature
                    if (moisture > 0.6) {
                      biome = 'forest';
                    } else if (temperature > 0.7 && moisture < 0.3) {
                      biome = 'desert';
                    } else {
                      biome = 'plains';
                    }
                  } else {
                    // Underwater - just assign something, will be water anyway
                    biome = 'plains';
                  }
                }
                
                // Store in heightmap
                heightmap[`${x},${z}`] = { height, biome };
              
                // Collect potential river sources (high points)
                if (height > 10 && Math.random() > 0.7) {
                  potentialRiverSources.push({ x, z });
                }
              }
            }
          };
          
          // Step 2: Generate rivers
          const generateRivers = () => {
            // Choose a random subset of sources for rivers
            const numRivers = 2 + Math.floor(Math.random() * 3); // 2-4 rivers
            
            for (let i = 0; i < numRivers && potentialRiverSources.length > 0; i++) {
              // Pick a random source
              const sourceIndex = Math.floor(Math.random() * potentialRiverSources.length);
              const source = potentialRiverSources[sourceIndex];
              potentialRiverSources.splice(sourceIndex, 1);
              
              // Initialize river path
              const riverPath: Array<{x: number, z: number}> = [];
              let current = { x: source.x, z: source.z };
              let steps = 0;
              const maxSteps = 200; // Prevent infinite loops
              
              // Create the river path
              while (steps < maxSteps) {
                riverPath.push(current);
                
                const key = `${current.x},${current.z}`;
                const height = heightmap[key]?.height || 0;
                
                // If we reached water level or edge of map, stop
                if (height <= waterLevel || !heightmap[key]) {
                  break;
                }
                
                // Find lowest neighbor
                const neighbors = [
                  { x: current.x + 1, z: current.z },
                  { x: current.x - 1, z: current.z },
                  { x: current.x, z: current.z + 1 },
                  { x: current.x, z: current.z - 1 },
                ];
                
                let lowestNeighbor = null;
                let lowestHeight = Infinity;
                
                for (const neighbor of neighbors) {
                  const neighborKey = `${neighbor.x},${neighbor.z}`;
                  if (heightmap[neighborKey]) {
                    const neighborHeight = heightmap[neighborKey].height;
                    if (neighborHeight < lowestHeight) {
                      lowestHeight = neighborHeight;
                      lowestNeighbor = neighbor;
                    }
                  }
                }
                
                // If we can't go lower, stop
                if (!lowestNeighbor || lowestHeight >= height) {
                  break;
                }
                
                // Move to lowest neighbor
                current = lowestNeighbor;
                steps++;
                
                // Erode the river path slightly
                if (heightmap[key].height > waterLevel + 1) {
                  heightmap[key].height -= 1;
                }
              }
              
              // Add the completed river path
              if (riverPath.length > 0) {
                riverPaths.push(riverPath);
              }
            }
          };
          
          // Step 3: Generate lakes
          const generateLakes = () => {
            // Create some lakes by lowering terrain in certain areas
            const numLakes = 2 + Math.floor(Math.random() * 3); // 2-4 lakes
            for (let i = 0; i < numLakes; i++) {
              const lakeX = Math.floor(Math.random() * (worldSize - 10)) - worldRadius + 5;
              const lakeZ = Math.floor(Math.random() * (worldSize - 10)) - worldRadius + 5;
              const lakeSize = 3 + Math.floor(Math.random() * 4); // Lakes of size 3-6
              
              for (let dx = -lakeSize; dx <= lakeSize; dx++) {
                for (let dz = -lakeSize; dz <= lakeSize; dz++) {
                  const distance = Math.sqrt(dx * dx + dz * dz);
                  if (distance <= lakeSize) {
                    const x = lakeX + dx;
                    const z = lakeZ + dz;
                    const key = `${x},${z}`;
                    
                    if (heightmap[key]) {
                      // Make the lake deeper toward the center
                      const depthFactor = 1 - (distance / lakeSize);
                      const targetHeight = Math.max(waterLevel - 1, waterLevel - Math.floor(depthFactor * 2));
                      
                      if (heightmap[key].height > targetHeight + 1) {
                        heightmap[key].height = targetHeight;
                      }
                    }
                  }
                }
              }
            }
          };
          
          // Step 4: Generate terrain
          const generateTerrain = (x1: number, x2: number) => {
            for (let x = x1; x <= x2; x++) {
              for (let z = -worldRadius; z <= worldRadius; z++) {
                // Skip if outside world radius
                if (Math.sqrt(x * x + z * z) > worldRadius) continue;
                
                const key = `${x},${z}`;
                const locationData = heightmap[key];
                
                if (!locationData) continue;
                
                const { height, biome } = locationData;
                
                // Check if this is part of a river
                const isRiver = riverPaths.some(path => 
                  path.some(pos => pos.x === x && pos.z === z)
                );
                
                // Generate blocks from bottom to top
                for (let y = 0; y <= height; y++) {
                  let type: ItemType;
                  
                  // Determine block type based on height and biome
                  if (y === 0) {
                    // Bedrock layer
                    type = 'stone';
                  } else if (y === height) {
                    // Surface layer depends on biome
                    switch (biome) {
                      case 'mountains':
                        type = y > 7 ? 'snow' : 'stone';
                        break;
                      case 'forest':
                        type = 'grass';
                        break;
                      case 'plains':
                        type = 'grass';
                        break;
                      case 'desert':
                        type = 'sand';
                        break;
                      case 'hills':
                        type = 'grass';
                        break;
                      default:
                        type = 'grass';
                    }
                  } else if (y >= height - 1) {
                    // Layer just below surface
                    switch (biome) {
                      case 'desert':
                        type = 'sand';
                        break;
                      case 'mountains':
                        type = y > 6 ? 'stone' : 'dirt';
                        break;
                      default:
                        type = 'dirt';
                    }
                  } else if (y >= height - 3) {
                    // Few layers down - mix of dirt and stone
                    type = Math.random() > 0.3 ? 'dirt' : 'stone';
                  } else {
                    // Deep underground
                    type = 'stone';
                  }
                  
                  // Skip water level and above if below water
                  if (y > waterLevel && height <= waterLevel) {
                    continue;
                  }
                  
                  // Add block
                  const id = uuidv4();
                  newItems[id] = {
                    id,
                    type,
                    position: { x, y, z },
                    rotation: 0,
                    scale: 1.0,
                    stackable: true,
                    rotatable: false
                  };
                }
                
                // Add water blocks
                if (height <= waterLevel || isRiver) {
                  for (let y = height + 1; y <= waterLevel; y++) {
                    const id = uuidv4();
                    newItems[id] = {
                      id,
                      type: 'water',
                      position: { x, y, z },
                      rotation: 0,
                      scale: 1.0,
                      stackable: false,
                      rotatable: false
                    };
                  }
                }
              }
            }
          };
          
          // Step 5: Define terrain features if we're including props
          const terrainFeatures: Array<{
            biome: BiomeType[];
            itemType: ItemType;
            count: number;
            scaleRange: [number, number];
            onlyAboveWater: boolean;
            minHeight?: number;
            maxHeight?: number;
            clustering: number;
          }> = includeProps ? [
            // Trees and forest features
            { 
              biome: ['forest'], 
              itemType: 'oak_tree', 
              count: 80, 
              scaleRange: [1.0, 1.5],
              onlyAboveWater: true,
              clustering: 0.6
            },
            { 
              biome: ['plains'], 
              itemType: 'oak_tree', 
              count: 30, 
              scaleRange: [1.0, 1.3],
              onlyAboveWater: true,
              clustering: 0.3
            },
            { 
              biome: ['forest', 'plains'], 
              itemType: 'bush', 
              count: 100, 
              scaleRange: [0.8, 1.2],
              onlyAboveWater: true,
              clustering: 0.5
            },
            
            // Rocky terrain features
            { 
              biome: ['mountains', 'hills'], 
              itemType: 'boulder', 
              count: 40, 
              scaleRange: [0.7, 1.4], 
              onlyAboveWater: true,
              minHeight: 5,
              clustering: 0.7
            },
            { 
              biome: ['mountains', 'hills', 'plains'], 
              itemType: 'small_rock', 
              count: 60, 
              scaleRange: [0.4, 0.8], 
              onlyAboveWater: true,
              clustering: 0.4
            },
            
            // Buildings and structures
            { 
              biome: ['plains', 'forest'], 
              itemType: 'wooden_hut', 
              count: 6, 
              scaleRange: [0.7, 0.9], 
              onlyAboveWater: true,
              clustering: 0.8
            },
            { 
              biome: ['plains', 'forest'], 
              itemType: 'log_cabin', 
              count: 3, 
              scaleRange: [0.7, 0.8], 
              onlyAboveWater: true,
              clustering: 0.8
            },
            { 
              biome: ['plains', 'forest', 'hills'], 
              itemType: 'tent', 
              count: 8, 
              scaleRange: [0.6, 0.8], 
              onlyAboveWater: true,
              clustering: 0.6
            },
            { 
              biome: ['mountains', 'hills'], 
              itemType: 'watchtower', 
              count: 3, 
              scaleRange: [0.7, 0.9], 
              onlyAboveWater: true,
              minHeight: 5,
              clustering: 0.3
            },
            
            // Misc features
            { 
              biome: ['plains', 'forest'], 
              itemType: 'campfire', 
              count: 8, 
              scaleRange: [0.6, 0.8], 
              onlyAboveWater: true,
              clustering: 0.7
            }
          ] : [];
        
          // Helper function to place features with clustering
          const placeFeatures = (feature: typeof terrainFeatures[0]) => {
            // Create initial random points as cluster centers
            const clusterCenters: Array<{x: number, z: number}> = [];
            const clusterCount = Math.ceil(feature.count * feature.clustering);
            
            for (let i = 0; i < clusterCount; i++) {
              // Find suitable biome locations for this feature
              let attempts = 0;
              const maxAttempts = 30;
              
              while (attempts < maxAttempts) {
                attempts++;
                
                // Pick random position
                const x = Math.floor(Math.random() * worldSize - worldRadius);
                const z = Math.floor(Math.random() * worldSize - worldRadius);
                
                // Check if within world bounds
                if (Math.sqrt(x * x + z * z) > worldRadius * 0.95) continue;
                
                // Check if suitable location (right biome)
                const key = `${x},${z}`;
                const locationData = heightmap[key];
                
                // Skip if no height data or not in right biome
                if (!locationData || !feature.biome.includes(locationData.biome)) {
                  continue;
                }
                
                // Don't place clusters too close to other clusters
                if (clusterCenters.some(center => 
                  Math.sqrt(Math.pow(center.x - x, 2) + Math.pow(center.z - z, 2)) < 10
                )) {
                  continue;
                }
                
                // This is a good location for a cluster
                clusterCenters.push({ x, z });
                break;
              }
            }
            
            // Now place items around cluster centers
            let placedCount = 0;
            const maxAttempts = feature.count * 5; // Limit attempts to prevent infinite loops
            let attempts = 0;
            
            while (placedCount < feature.count && attempts < maxAttempts) {
              attempts++;
              
              // Choose a random cluster center
              if (clusterCenters.length === 0) break;
              const center = clusterCenters[Math.floor(Math.random() * clusterCenters.length)];
              
              // Calculate position with some randomness around the center
              const clusterRadius = 3 + Math.random() * 3; // 3-6 block radius
              const angle = Math.random() * Math.PI * 2;
              const distance = Math.random() * clusterRadius;
              
              const x = Math.round(center.x + Math.cos(angle) * distance);
              const z = Math.round(center.z + Math.sin(angle) * distance);
              
              // Skip if outside world bounds
              if (Math.sqrt(x * x + z * z) > worldRadius) {
                continue;
              }
              
              const key = `${x},${z}`;
              const locationData = heightmap[key];
              
              // Skip if no height data or not in right biome
              if (!locationData || !feature.biome.includes(locationData.biome)) {
                continue;
              }
              
              const { height } = locationData;
              
              // Check water level constraint
              if (feature.onlyAboveWater && height <= waterLevel) {
                continue;
              }
              
              // Check height constraints if specified
              if (feature.minHeight !== undefined && height < feature.minHeight) {
                continue;
              }
              if (feature.maxHeight !== undefined && height > feature.maxHeight) {
                continue;
              }
              
              // For buildings, check if area is flat
              if (['wooden_hut', 'log_cabin', 'tent', 'watchtower'].includes(feature.itemType)) {
                let isFlat = true;
                const checkRadius = feature.itemType === 'watchtower' ? 1 : 2;
                
                for (let dx = -checkRadius; dx <= checkRadius && isFlat; dx++) {
                  for (let dz = -checkRadius; dz <= checkRadius && isFlat; dz++) {
                    if (dx === 0 && dz === 0) continue;
                    
                    const nx = x + dx;
                    const nz = z + dz;
                    const neighborKey = `${nx},${nz}`;
                    
                    if (!heightmap[neighborKey]) {
                      isFlat = false;
                    } else {
                      const neighborHeight = heightmap[neighborKey].height;
                      if (Math.abs(neighborHeight - height) > 1) {
                        isFlat = false;
                      }
                    }
                  }
                }
                
                if (!isFlat) continue;
              }
              
              // Place the feature
              const id = uuidv4();
              const scale = feature.scaleRange[0] + Math.random() * (feature.scaleRange[1] - feature.scaleRange[0]);
              
              // Determine Y position offset based on item type to prevent items from being buried
              let yOffset = 0;
              
              // Apply appropriate Y offset based on item type
              switch (feature.itemType) {
                case 'oak_tree':
                  yOffset = 1.0;
                  break;
                case 'wooden_hut':
                case 'log_cabin':
                  yOffset = 1.5;
                  break;
                case 'tent':
                  yOffset = 1.0;
                  break;
                case 'watchtower':
                  yOffset = 1.2;
                  break;
                case 'campfire':
                  yOffset = 0.8;
                  break;
                case 'boulder':
                  yOffset = 1.0;
                  break;
                case 'small_rock':
                  yOffset = 0.6;
                  break;
                case 'bush':
                  yOffset = 1.0;
                  break;
                default:
                  yOffset = 1.0;
              }
              
              newItems[id] = {
                id,
                type: feature.itemType,
                position: { x, y: height + yOffset, z },
                rotation: Math.random() * Math.PI * 2,
                scale,
                stackable: false,
                rotatable: true
              };
              
              placedCount++;
            }
          };
          
          // Run the generation process in chunks
          const totalSteps = steps.length;
          
          const executeNextStep = () => {
            if (currentStep < steps.length) {
              try {
                // Execute the current step
                steps[currentStep]();
                currentStep++;
                
                // Update progress
                const progress = Math.round((currentStep / totalSteps) * 100);
                set({ generationProgress: progress });
                
                // Schedule next step with a small delay to ensure UI updates
                setTimeout(() => {
                  requestAnimationFrame(executeNextStep);
                }, 50); // Small delay between steps
              } catch (error) {
                console.error('Error during world generation step:', error);
                set({ 
                  isGeneratingWorld: false,
                  generationProgress: 0
                });
              }
            } else {
              // All steps complete, update the store with the new items
              set({ 
                items: newItems,
                isGeneratingWorld: false,
                generationProgress: 100
              });
              
              // Adjust camera based on world size
              adjustCamera(worldRadius);
            }
          };
          
          // Start the chunked generation process
          executeNextStep();
        };
        
        // Start the random world generation
        setTimeout(generateRandomWorldChunked, 100);
      }
    });
  },
  
  // Keep the existing generateRandomWorld function for backward compatibility
  generateRandomWorld: () => {
    // Call the new generateWorld function with default random world settings
    useGameStore.getState().generateWorld({
      type: 'random',
      size: 30, // Default world radius
      includeProps: true,
      waterLevel: 2
    });
  },
  
  // Preview actions
  setPlacementPreview: (preview: PlacementPreview | null) =>
    set((state) => {
      // Only show preview if in build mode
      if (!state.buildMode) return { placementPreview: null };
      return { placementPreview: preview };
    }),
  
  // Build mode actions
  setBuildMode: (active: boolean) => 
    set({ 
      buildMode: active,
      // Clear placement preview when toggling build mode
      placementPreview: null 
    }),
    
  toggleBuildMode: () => 
    set((state) => ({ 
      buildMode: !state.buildMode,
      // Clear placement preview when toggling build mode
      placementPreview: null
    })),
  
  // Debug mode actions
  toggleDebugMode: () => 
    set((state) => ({ debugMode: !state.debugMode })),
})); 