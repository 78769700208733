import React from 'react';

const Campfire: React.FC = () => {
  // Colors
  const stoneColor = "#808080"; // Gray
  const logColor = "#8B4513"; // SaddleBrown
  const fireColor = "#FF4500"; // OrangeRed
  
  return (
    <group position={[0, -0.1, 0]}>
      {/* Stone circle */}
      {Array.from({ length: 8 }).map((_, i) => {
        const angle = (i / 8) * Math.PI * 2;
        const x = Math.cos(angle) * 0.4;
        const z = Math.sin(angle) * 0.4;
        return (
          <mesh key={i} position={[x, 0.1, z]} rotation={[0, angle, 0]} castShadow receiveShadow>
            <boxGeometry args={[0.2, 0.2, 0.2]} />
            <meshStandardMaterial color={stoneColor} />
          </mesh>
        );
      })}

      {/* Logs */}
      <mesh position={[0.1, 0.15, 0]} rotation={[0, Math.PI / 4, 0]} castShadow receiveShadow>
        <cylinderGeometry args={[0.08, 0.08, 0.6, 8]} />
        <meshStandardMaterial color={logColor} />
      </mesh>
      <mesh position={[-0.1, 0.15, 0]} rotation={[0, -Math.PI / 4, 0]} castShadow receiveShadow>
        <cylinderGeometry args={[0.08, 0.08, 0.6, 8]} />
        <meshStandardMaterial color={logColor} />
      </mesh>
      <mesh position={[0, 0.15, 0.1]} rotation={[0, 0, Math.PI / 2]} castShadow receiveShadow>
        <cylinderGeometry args={[0.08, 0.08, 0.6, 8]} />
        <meshStandardMaterial color={logColor} />
      </mesh>

      {/* Fire */}
      <mesh position={[0, 0.3, 0]} castShadow receiveShadow>
        <coneGeometry args={[0.2, 0.4, 8]} />
        <meshStandardMaterial color={fireColor} emissive={fireColor} emissiveIntensity={2} />
      </mesh>
    </group>
  );
};

export default Campfire; 