import React from 'react';
import './WelcomeScreen.css';
import { adjustCamera } from '../../App';
import { useGameStore } from '../../store/gameStore';

interface WelcomeScreenProps {
  onClose: () => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ onClose }) => {
  const items = useGameStore(state => state.items);
  
  // Handler for the start button
  const handleStart = () => {
    // First close the welcome screen
    onClose();
    
    // Get the current world size from the items
    // Find the item farthest from center to estimate world size
    let maxDistance = 0;
    Object.values(items).forEach(item => {
      const distance = Math.sqrt(
        item.position.x * item.position.x + 
        item.position.z * item.position.z
      );
      maxDistance = Math.max(maxDistance, distance);
    });
    
    // Set a default world size if no items exist yet
    const worldSize = maxDistance > 0 ? maxDistance : 15;
    
    // Adjust camera based on world size
    adjustCamera(worldSize);
  };
  
  return (
    <div className="welcome-overlay">
      <div className="welcome-card">
        <h1>Welcome to Builder!</h1>
        <div className="welcome-content">
          <div className="welcome-section">
            <h2>🎮 Controls</h2>
            <ul>
              <li><strong>Left-click and drag</strong>: Rotate camera</li>
              <li><strong>Right-click and drag</strong>: Move camera</li>
              <li><strong>Left-click</strong>: Place selected block</li>
              <li><strong>Right-click</strong>: Open item menu</li>
              <li><strong>Mouse wheel</strong>: Zoom in/out</li>
            </ul>
          </div>
          
          <div className="welcome-section">
            <h2>🏗️ Building Tips</h2>
            <ul>
              <li>Select blocks from the toolbar at the bottom</li>
              <li>Use "Generate Random World" to start with terrain</li>
              <li>Right-click on items to rotate or delete them</li>
              <li>Some items can only be placed on top of others</li>
            </ul>
          </div>
        </div>
        
        <div className="welcome-footer">
          <button className="start-button" onClick={handleStart}>
            Start Building!
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen; 