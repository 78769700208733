import React from 'react';
import { useGameStore } from '../../store/gameStore';
import './BuildButton.css';

const BuildButton: React.FC = () => {
  const buildMode = useGameStore(state => state.buildMode);
  const toggleBuildMode = useGameStore(state => state.toggleBuildMode);
  
  // Log when button is clicked for debugging
  const handleClick = () => {
    console.log('Build button clicked, toggling build mode');
    toggleBuildMode();
  };
  
  // Don't render the button when in build mode
  if (buildMode) return null;
  
  return (
    <button 
      className={`build-button`}
      onClick={handleClick}
      title="Enter Build Mode"
    >
      <span className="build-icon">🔨</span>
      <span className="build-text">BUILD</span>
    </button>
  );
};

export default BuildButton; 