import React, { useRef, forwardRef, useState, useEffect, createContext, useContext } from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { ItemType } from '../../types';
import { useGameStore } from '../../store/gameStore';
import { getItemColor, getPropModelInfo, getPropScale, isItemStackable, getItemsByCategory, getPropBaseHeight } from '../../config/blockConfig';
import * as PropModels from '../../models/props';
import ItemMenu from '../ui/ItemMenu';

interface ItemProps {
  id: string;
  position: [number, number, number];
  type: ItemType;
  rotation?: number;
  scale?: number;
  stackable?: boolean;
  rotatable?: boolean;
  castShadow?: boolean;
  receiveShadow?: boolean;
}

// Separate component to handle GLB loading - this ensures hook rules are followed
interface GlbLoaderProps {
  modelPath: string;
  position: [number, number, number];
  rotation: number;
  scale: number;
  onClick: (event: ThreeEvent<MouseEvent>) => void;
  onPointerDown: (event: ThreeEvent<PointerEvent>) => void;
  onPointerMove: (event: ThreeEvent<PointerEvent>) => void;
  onPointerUp: (event: ThreeEvent<PointerEvent>) => void;
  castShadow: boolean;
  receiveShadow: boolean;
  fallback: React.ReactNode;
}

const GlbLoader: React.FC<GlbLoaderProps> = ({
  modelPath,
  position,
  rotation,
  scale,
  onClick,
  onPointerDown,
  onPointerMove,
  onPointerUp,
  castShadow,
  receiveShadow,
  fallback
}) => {
  // State for tracking render errors (separate from load errors)
  const [renderError, setRenderError] = useState(false);
  
  // Always call hooks at the top level, unconditionally
  const gltf = useGLTF(modelPath);
  
  // Use useEffect to validate the loaded model
  useEffect(() => {
    const isInvalid = !gltf?.scene || !gltf.scene.children || gltf.scene.children.length === 0;
    if (isInvalid) {
      console.warn(`Invalid scene loaded for ${modelPath}`);
      setRenderError(true);
    } else {
      setRenderError(false);
    }
  }, [gltf, modelPath]);
  
  // If we have a render error, return the fallback
  if (renderError || !gltf.scene) {
    return <>{fallback}</>;
  }
  
  // Function to safely clone and render the scene
  const renderScene = () => {
    try {
      const clonedScene = gltf.scene.clone();
      clonedScene.traverse((node) => {
        if ((node as THREE.Mesh).isMesh) {
          (node as THREE.Mesh).castShadow = castShadow;
          (node as THREE.Mesh).receiveShadow = receiveShadow;
        }
      });
      
      return (
        <primitive
          object={clonedScene}
          position={position}
          rotation={[0, rotation, 0]}
          scale={[scale, scale, scale]}
          onClick={onClick}
          onPointerDown={onPointerDown}
          onPointerMove={onPointerMove}
          onPointerUp={onPointerUp}
        />
      );
    } catch (err) {
      console.error(`Error rendering model ${modelPath}:`, err);
      // If we encounter an error during rendering, show the fallback
      // but we need to update state in useEffect, not during render
      setRenderError(true);
      return null;
    }
  };
  
  // If a rendering error occurs, this will be caught by the error state
  // and fallback will be shown on next render
  return renderError ? <>{fallback}</> : renderScene();
};

// Create a context for managing item menus
interface MenuContextType {
  openMenu: (id: string, position: { x: number, y: number }, isRotatable: boolean, itemType: ItemType) => void;
  closeMenu: () => void;
  isMenuOpen: boolean;
}

const MenuContext = createContext<MenuContextType | null>(null);

// Menu Manager component that will render outside of Three.js context
export const MenuManager: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState<{
    itemId: string;
    position: { x: number, y: number };
    isRotatable: boolean;
    itemType: ItemType;
  } | null>(null);
  
  const openMenu = (itemId: string, position: { x: number, y: number }, isRotatable: boolean, itemType: ItemType) => {
    setActiveMenu({ itemId, position, isRotatable, itemType });
  };
  
  const closeMenu = () => {
    setActiveMenu(null);
  };
  
  return (
    <MenuContext.Provider value={{ 
      openMenu, 
      closeMenu,
      isMenuOpen: activeMenu !== null 
    }}>
      {children}
      {activeMenu && ReactDOM.createPortal(
        <ItemMenu 
          itemId={activeMenu.itemId}
          position={activeMenu.position}
          isRotatable={activeMenu.isRotatable}
          itemType={activeMenu.itemType}
          onClose={closeMenu}
        />,
        document.body
      )}
    </MenuContext.Provider>
  );
};

// Hook to use the menu context
export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenuContext must be used within a MenuManager");
  }
  return context;
};

const Item = forwardRef<THREE.Object3D, ItemProps>(({
  id,
  position,
  type,
  rotation = 0,
  scale = 1,
  stackable = false,
  rotatable = false,
  castShadow = true,
  receiveShadow = true
}, ref) => {
  const [x, y, z] = position;
  const addItem = useGameStore(state => state.addItem);
  const selectedItemType = useGameStore(state => state.selectedItemType);
  
  // Get menu context
  const { openMenu, isMenuOpen } = useMenuContext();
  
  const innerRef = useRef<THREE.Object3D>(null);
  const actualRef = ref || innerRef;
  
  const mouseDownPos = useRef<[number, number] | null>(null);
  const clickedFace = useRef<number | null>(null);
  const touchStartTime = useRef<number | null>(null);
  const longPressTimeout = useRef<NodeJS.Timeout | null>(null);
  
  // Prevent text selection and callout on iOS devices
  useEffect(() => {
    // Function to prevent default behavior for touchstart events
    const preventDefaultTouchBehavior = (e: TouchEvent) => {
      // Only prevent default if we're interacting with the game area
      if (e.target && (e.target as HTMLElement).closest('canvas')) {
        e.preventDefault();
      }
    };

    // Global touch move handler to detect camera movement even when touch starts outside item
    const globalTouchMoveHandler = (e: TouchEvent) => {
      // If we have an active long press timeout, check for movement
      if (longPressTimeout.current) {
        if (e.touches.length > 0) {
          // If touch is moving, likely rotating camera
          cameraRotating.current = true;
          
          // Cancel long press
          clearTimeout(longPressTimeout.current);
          longPressTimeout.current = null;
        }
      }
    };

    // Add event listeners with passive: false to allow preventDefault
    document.addEventListener('touchstart', preventDefaultTouchBehavior, { passive: false });
    document.addEventListener('touchmove', globalTouchMoveHandler, { passive: true });
    
    return () => {
      document.removeEventListener('touchstart', preventDefaultTouchBehavior);
      document.removeEventListener('touchmove', globalTouchMoveHandler);
    };
  }, []);
  
  // Track touch movement for gesture disambiguation
  const touchMoved = useRef<boolean>(false);
  const initialTouchDistance = useRef<number>(0);
  const lastTouchPosition = useRef<[number, number] | null>(null);
  const touchMoveThreshold = 8; // Lower threshold to be more sensitive to movement
  const touchMovementHistory = useRef<number[]>([]);
  const cameraRotating = useRef<boolean>(false);

  useEffect(() => {
    // Clean up function to reset touch tracking when component unmounts
    return () => {
      touchMoved.current = false;
      initialTouchDistance.current = 0;
      lastTouchPosition.current = null;
      touchMovementHistory.current = [];
      cameraRotating.current = false;
    };
  }, []);

  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    
    // Store initial position for all pointer types
    mouseDownPos.current = [event.clientX, event.clientY];
    lastTouchPosition.current = [event.clientX, event.clientY];
    
    // For left clicks, store the face that was clicked
    if (event.button === 0) {
      clickedFace.current = Math.floor(event.faceIndex! / 2);
    }
    
    // Reset movement tracking
    touchMoved.current = false;
    initialTouchDistance.current = 0;
    touchMovementHistory.current = [];
    cameraRotating.current = false;
    
    // For touch events, initialize tracking variables
    if (event.pointerType === 'touch') {
      touchStartTime.current = Date.now();
      
      // Set a timeout for long press detection
      longPressTimeout.current = setTimeout(() => {
        // Only show menu if there's been very minimal movement (not a camera rotation)
        if (!touchMoved.current && !cameraRotating.current && mouseDownPos.current) {
          // Prevent default behavior on the native event
          if (event.nativeEvent) {
            event.nativeEvent.preventDefault();
          }
          
          // Show the menu when long press is detected
          openMenu(id, { x: event.clientX, y: event.clientY }, rotatable, type);
          
          // Clear touch start time to prevent normal tap handling
          touchStartTime.current = null;
          
          // Mark the pointer as handled to prevent conflicts
          event.stopPropagation();
          
          // Clear placement preview when showing menu
          useGameStore.getState().setPlacementPreview(null);
        }
      }, 600); // Slightly shorter than before for better responsiveness
    }
  };
  
  // Add a new handler for pointer move to better detect drags
  const handlePointerMove = (event: ThreeEvent<PointerEvent>) => {
    // Only process for touch events
    if (event.pointerType === 'touch') {
      // Update last touch position if we have a starting position
      if (lastTouchPosition.current) {
        const [lastX, lastY] = lastTouchPosition.current;
        const dx = event.clientX - lastX;
        const dy = event.clientY - lastY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        // Store movement in history (keep last 5 movements)
        touchMovementHistory.current.push(distance);
        if (touchMovementHistory.current.length > 5) {
          touchMovementHistory.current.shift();
        }
        
        // Check for consistent movement pattern indicating camera rotation
        // If we have several consecutive movements, it's likely a camera rotation
        if (touchMovementHistory.current.length >= 3) {
          const hasConsistentMovement = touchMovementHistory.current.every(d => d > 1); // Any movement
          if (hasConsistentMovement) {
            cameraRotating.current = true;
            
            // Clear placement preview during camera rotation
            if (!isMenuOpen) {
              useGameStore.getState().setPlacementPreview(null);
            }
          }
        }
        
        // Update total distance moved from start
        if (mouseDownPos.current) {
          const [startX, startY] = mouseDownPos.current;
          const totalDistance = Math.sqrt(
            Math.pow(event.clientX - startX, 2) + 
            Math.pow(event.clientY - startY, 2)
          );
          initialTouchDistance.current = totalDistance;
          
          // If moved beyond threshold, mark as a drag gesture
          if (totalDistance > touchMoveThreshold || distance > 3) {
            touchMoved.current = true;
          }
        }
        
        // Update last position
        lastTouchPosition.current = [event.clientX, event.clientY];
        
        // If we're detecting movement, cancel any pending long press
        if (touchMoved.current || cameraRotating.current) {
          if (longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
            longPressTimeout.current = null;
          }
        }
      }
    }
  };
  
  const handlePointerUp = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    
    // Clear long press timeout if it exists
    if (longPressTimeout.current) {
      clearTimeout(longPressTimeout.current);
      longPressTimeout.current = null;
    }
    
    // Only process if we have a mouseDownPos
    if (mouseDownPos.current) {
      const [startX, startY] = mouseDownPos.current;
      const moveDistance = Math.sqrt(
        Math.pow(event.clientX - startX, 2) + 
        Math.pow(event.clientY - startY, 2)
      );
      
      // Handle touch specific logic
      if (event.pointerType === 'touch') {
        // For touch, we check if it's been marked as moved or camera rotating
        const isClick = !touchMoved.current && !cameraRotating.current && moveDistance < touchMoveThreshold;
        
        // Handle as a tap only if it wasn't a long press, drag, or camera rotation
        if (isClick && touchStartTime.current && (Date.now() - touchStartTime.current) < 500) {
          // This was a short tap - handle as a left click
          handleTapAsLeftClick(event);
        } else {
          // If it wasn't a valid tap, clear the placement preview
          useGameStore.getState().setPlacementPreview(null);
        }
      } else {
        // For mouse interactions, use the standard logic
        const clickThreshold = 5; // 5 pixels threshold for mouse
        const isClick = moveDistance < clickThreshold;
        
        if (event.button === 2 && isClick) { // Right click
          openMenu(id, { x: event.clientX, y: event.clientY }, rotatable, type);
          // Clear placement preview when showing context menu
          useGameStore.getState().setPlacementPreview(null);
        } 
        else if (event.button === 0 && isClick) { // Left click, not drag
          handleTapAsLeftClick(event);
        } else {
          // If it wasn't a valid click, clear the placement preview
          useGameStore.getState().setPlacementPreview(null);
        }
      }
    }
    
    // Reset all tracking variables
    mouseDownPos.current = null;
    touchStartTime.current = null;
    clickedFace.current = null;
    touchMoved.current = false;
    initialTouchDistance.current = 0;
    lastTouchPosition.current = null;
    touchMovementHistory.current = [];
    cameraRotating.current = false;
  };

  // Extracted common logic for handling left clicks/taps
  const handleTapAsLeftClick = (event: ThreeEvent<PointerEvent>) => {
    // Don't allow item placement if a menu is open
    if (isMenuOpen) {
      useGameStore.getState().setPlacementPreview(null);
      return;
    }
    
    // Check if we're in build mode
    const buildMode = useGameStore.getState().buildMode;
    if (!buildMode) {
      useGameStore.getState().setPlacementPreview(null);
      return;
    }
    
    // Check if there's an active placement preview
    const placementPreview = useGameStore.getState().placementPreview;
    
    if (placementPreview && placementPreview.valid) {
      // Use the position from the placement preview
      const { position, type } = placementPreview;
      addItem(position.x, position.y, position.z, type);
      return;
    }
    
    // Legacy placement code - only used as fallback if no preview exists
    // Get the selected item's stackable property
    const selectedIsStackable = isItemStackable(selectedItemType);
    
    // If clicking on a non-stackable item or trying to place a non-stackable item on another non-stackable item, don't allow placement
    if (!stackable && !selectedIsStackable) {
      useGameStore.getState().setPlacementPreview(null);
      return;
    }
    
    // Only stackable items can have other items directly attached to their sides
    const face = clickedFace.current;
    
    if (face !== null) {
      const [dx, dy, dz] = [
        face === 0 ? 1 : face === 1 ? -1 : 0,
        face === 2 ? 1 : face === 3 ? -1 : 0,
        face === 4 ? 1 : face === 5 ? -1 : 0
      ];
      
      // For stackable items, allow placement on any face
      if (stackable) {
        addItem(x + dx, y + dy, z + dz, selectedItemType);
      } 
      // For non-stackable items, only allow placement on top
      else if (face === 2) { // Top face
        addItem(x, y + 1, z, selectedItemType);
      }
      
      // Clear the placement preview after adding the item
      useGameStore.getState().setPlacementPreview(null);
    } else {
      // If there's no face, just clear the preview
      useGameStore.getState().setPlacementPreview(null);
    }
  };

  // Empty click handler - we handle everything through pointer events now
  const handleClick = (event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();
    // We don't need to do anything here because we handle item placement in handlePointerUp
  };

  // Determine if this is a block-like item (simple box) or a prop-like item (complex model)
  // Get all blocks from the blocks category
  const blockItems = getItemsByCategory('blocks');
  const isBlockLike = blockItems.includes(type);
  
  // Get model info for prop-like items - needed for both component and GLB types
  const modelInfo = !isBlockLike ? getPropModelInfo(type) : null;
  
  // Block-like items use a simple colored box
  if (isBlockLike) {
    return (
      <mesh
        ref={actualRef as React.RefObject<THREE.Mesh>}
        position={position}
        rotation={[0, rotation || 0, 0]}
        scale={scale}
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        castShadow={castShadow}
        receiveShadow={receiveShadow}
      >
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color={getItemColor(type)} />
      </mesh>
    );
  }
  
  // Get prop scale and adjust Y position
  const defaultScale = getPropScale(type);
  const baseHeight = getPropBaseHeight(type);
  const adjustedY = y - baseHeight;
  
  // If it's a component type prop
  if (modelInfo && modelInfo.type === 'component') {
    const Component = PropModels[modelInfo.path as keyof typeof PropModels];
    if (!Component) {
      console.error(`Component ${modelInfo.path} not found`);
      return null;
    }
    
    return (
      <group
        ref={actualRef as React.RefObject<THREE.Group>}
        position={[x, adjustedY, z]}
        rotation={[0, rotation, 0]}
        scale={[scale * defaultScale, scale * defaultScale, scale * defaultScale]}
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
      >
        <Component />
      </group>
    );
  }
  
  // If it's a GLB type prop, use our dedicated loader component
  if (modelInfo && modelInfo.type === 'glb' && modelInfo.path) {
    return (
      <GlbLoader
        modelPath={modelInfo.path}
        position={[x, adjustedY, z]}
        rotation={rotation}
        scale={scale * defaultScale}
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        castShadow={castShadow}
        receiveShadow={receiveShadow}
        fallback={null}
      />
    );
  }
  
  // Fallback for any other case
  console.warn(`Using fallback rendering for item type: ${type}`);
  return null;
});

export default Item; 