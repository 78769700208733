import { create } from 'zustand';

export interface SettingsState {
  // Rendering settings
  showShadows: boolean;
  lightingMode: 'day' | 'sunset' | 'night';
  renderDistance: number;
  enableOcclusionCulling: boolean;
  performanceMode: boolean; // Use simplified rendering for better performance
  memoryOptimization: boolean; // Use reduced memory footprint
  
  // Actions
  setShowShadows: (show: boolean) => void;
  setLightingMode: (mode: 'day' | 'sunset' | 'night') => void;
  setRenderDistance: (distance: number) => void;
  setEnableOcclusionCulling: (enable: boolean) => void;
  setPerformanceMode: (enable: boolean) => void;
  setMemoryOptimization: (enable: boolean) => void;
}

export const useSettingsStore = create<SettingsState>((set) => ({
  // Default settings
  showShadows: false,
  lightingMode: 'sunset',
  renderDistance: 50,
  enableOcclusionCulling: true,
  performanceMode: false, // Default to normal rendering
  memoryOptimization: true, // Default to optimized memory usage
  
  // Actions
  setShowShadows: (show: boolean) => set({ showShadows: show }),
  setLightingMode: (mode: 'day' | 'sunset' | 'night') => set({ lightingMode: mode }),
  setRenderDistance: (distance: number) => set({ renderDistance: distance }),
  setEnableOcclusionCulling: (enable: boolean) => set({ enableOcclusionCulling: enable }),
  setPerformanceMode: (enable: boolean) => set({ performanceMode: enable }),
  setMemoryOptimization: (enable: boolean) => set({ memoryOptimization: enable }),
})); 