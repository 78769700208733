import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { Sky, ContactShadows, SoftShadows } from '@react-three/drei';
import { useGameStore } from '../../store/gameStore';
import { useSettingsStore } from '../../store/settingsStore';
import Item from './Item';
import { useMenuContext } from './Item';
import PlacementPreview from './PlacementPreview';
import { isItemStackable, getItemsByCategory } from '../../config/blockConfig';
import InstancedBlocks from './InstancedBlocks';

const Game: React.FC = () => {
  const items = useGameStore(state => state.items);
  const setVisibleItems = useGameStore(state => state.setVisibleItems);
  const selectedItemType = useGameStore(state => state.selectedItemType);
  const placementPreview = useGameStore(state => state.placementPreview);
  const setPlacementPreview = useGameStore(state => state.setPlacementPreview);
  const buildMode = useGameStore(state => state.buildMode);
  const addItem = useGameStore(state => state.addItem);
  
  // Get menu context for closing menus
  const { closeMenu, isMenuOpen } = useMenuContext();
  
  // Settings
  const showShadows = useSettingsStore(state => state.showShadows);
  const lightingMode = useSettingsStore(state => state.lightingMode);
  const renderDistance = useSettingsStore(state => state.renderDistance);
  const enableOcclusionCulling = useSettingsStore(state => state.enableOcclusionCulling);
  const memoryOptimization = useSettingsStore(state => state.memoryOptimization);
  
  const [showCullingDebug, setShowCullingDebug] = useState<boolean>(false);
  
  const frustumRef = useRef(new THREE.Frustum());
  const matrixRef = useRef(new THREE.Matrix4());
  const meshRefs = useRef<Record<string, THREE.Object3D>>({});
  
  // Track performance optimizations
  const lastFrameTime = useRef<number>(0);
  const skipHeavyOperations = useRef<boolean>(false);
  const FRAME_OPTIMIZATION_THRESHOLD = 30; // Allow up to 33ms per frame (30fps)
  
  // Block position cache for occlusion checks - will be cleared periodically
  const blockPositionCache = useRef<Record<string, boolean>>({});
  const lastCacheClearTime = useRef<number>(0);
  const CACHE_CLEAR_INTERVAL = 5000; // Clear cache every 5 seconds
  
  // Track initial render state to ensure first renders are complete
  const initialRenderCount = useRef<number>(0);
  const INITIAL_RENDER_COUNT = 10; // Ensure first 10 frames render completely
  
  // Track frame rate to detect stability issues
  const frameRateHistory = useRef<number[]>([]);
  const MAX_HISTORY_LENGTH = 5;
  const isRenderingStable = useRef<boolean>(false);
  
  // Occlusion culling - check if a block is completely surrounded by other solid blocks
  const isBlockOccluded = useCallback((x: number, y: number, z: number): boolean => {
    // Skip occlusion check for blocks at the edge of the world or blocks that need to be visible
    if (y <= 0) return false; // Don't occlude bottom layer blocks
    
    // Generate position keys for the 6 adjacent blocks
    const positionKeys = [
      `${x+1},${y},${z}`, // right
      `${x-1},${y},${z}`, // left
      `${x},${y+1},${z}`, // top
      `${x},${y-1},${z}`, // bottom
      `${x},${y},${z+1}`, // front
      `${x},${y},${z-1}`  // back
    ];
    
    // Check if all 6 adjacent positions have blocks
    for (const key of positionKeys) {
      if (!blockPositionCache.current[key]) {
        return false; // If any adjacent position doesn't have a block, this block is not occluded
      }
    }
    
    return true; // Block is completely surrounded by other blocks
  }, []);
  
  // Refs for raycasting
  const mouse = useRef<THREE.Vector2>(new THREE.Vector2());
  const raycaster = useMemo(() => new THREE.Raycaster(), []);
  const mouseDownRef = useRef(false);
  const lastUpdateTime = useRef<number>(0);
  const lastPreviewState = useRef<any>(null);
  const updatePending = useRef<boolean>(false);
  const animationFrameId = useRef<number | null>(null);
  const throttleTime = 50; // ms between updates
  
  // Track mouse/touch movement to prevent accidental placement during camera movement
  const pointerDownPosition = useRef<{ x: number, y: number } | null>(null);
  const hasMovedSignificantly = useRef<boolean>(false);
  const MOVEMENT_THRESHOLD = 5; // Pixels threshold for considering a significant movement
  
  // Helper function to check if pointer has moved significantly
  const checkSignificantMovement = useCallback((currentX: number, currentY: number): boolean => {
    if (pointerDownPosition.current) {
      const dx = currentX - pointerDownPosition.current.x;
      const dy = currentY - pointerDownPosition.current.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance > MOVEMENT_THRESHOLD) {
        hasMovedSignificantly.current = true;
      }
    }
    
    return hasMovedSignificantly.current;
  }, []);
  
  // Get the scene and camera from react-three-fiber
  const { scene, camera, gl } = useThree();
  
  // Update fog when render distance changes
  useEffect(() => {
    // Clear any existing fog
    scene.fog = null;
    
    // Apply fog based on render distance
    // Use exponential fog for more realistic distance fading
    const fogColor = lightingMode === 'night' 
      ? new THREE.Color('#1a1a2e') 
      : lightingMode === 'sunset' 
        ? new THREE.Color('#a86032') 
        : new THREE.Color('#e0f7ff');
    
    // The fog density controls how quickly objects fade with distance
    // Lower values = fog starts further away
    if (renderDistance <= 0) {
      // For render distance of 0, use extremely dense fog for visibility of 0
      scene.fog = new THREE.FogExp2(fogColor, 1.0);
    } else {
      // For exponential fog, we need to adjust the density based on render distance
      const fogDensity = 2.0 / (renderDistance * renderDistance);
      scene.fog = new THREE.FogExp2(fogColor, fogDensity);
    }
    
    return () => {
      // Cleanup
      scene.fog = null;
    };
  }, [renderDistance, lightingMode, scene]);

  useFrame(({ camera, invalidate }) => {
    // Track frame time for performance optimization
    const now = performance.now();
    const frameTime = now - lastFrameTime.current || 0;
    lastFrameTime.current = now;
    
    // Track frame rate history to detect stability
    if (lastFrameTime.current > 0) {
      frameRateHistory.current.push(1000 / frameTime);
      if (frameRateHistory.current.length > MAX_HISTORY_LENGTH) {
        frameRateHistory.current.shift();
      }
      
      // Calculate average and variance to determine stability
      if (frameRateHistory.current.length === MAX_HISTORY_LENGTH) {
        const avg = frameRateHistory.current.reduce((a, b) => a + b, 0) / MAX_HISTORY_LENGTH;
        const variance = frameRateHistory.current.reduce((a, b) => a + Math.pow(b - avg, 2), 0) / MAX_HISTORY_LENGTH;
        
        // Consider rendering stable if variance is low
        isRenderingStable.current = variance < 100; // Arbitrary threshold for stability
      }
    }
    
    // Ensure initial renders are not skipped
    const isInitialRender = initialRenderCount.current < INITIAL_RENDER_COUNT;
    if (isInitialRender) {
      initialRenderCount.current++;
      // During initial rendering, force a re-render for the next frame
      invalidate();
    }
    
    // Clear block position cache periodically
    if (now - lastCacheClearTime.current > CACHE_CLEAR_INTERVAL) {
      blockPositionCache.current = {};
      lastCacheClearTime.current = now;
    }
    
    // Simple performance optimization: Skip expensive operations if frames are slow
    skipHeavyOperations.current = frameTime > FRAME_OPTIMIZATION_THRESHOLD;
    
    // Update frustum
    matrixRef.current.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustumRef.current.setFromProjectionMatrix(matrixRef.current);
    
    // Create a new set for visible items
    const visibleItems = new Set<string>();
    let occludedBlocksCount = 0;
    let frustumCulledBlocksCount = 0;
    let totalProcessedItems = 0;
    
    // First pass: Build the block position cache for occlusion testing
    // Skip if we're having performance issues
    if (!skipHeavyOperations.current) {
      Object.entries(items).forEach(([id, item]) => {
        // Skip non-block items (like props) for occlusion test
        const blockTypes = getItemsByCategory('blocks');
        if (!blockTypes.includes(item.type)) return;
        
        // Store in position cache for occlusion testing
        const { x, y, z } = item.position;
        const roundedX = Math.round(x);
        const roundedY = Math.round(y);
        const roundedZ = Math.round(z);
        blockPositionCache.current[`${roundedX},${roundedY},${roundedZ}`] = true;
      });
    }
    
    // Second pass: Apply culling
    Object.entries(items).forEach(([id, item]) => {
      totalProcessedItems++;
      // For blocks, we don't need individual mesh references since they're rendered with instanced mesh
      // Just check position and distance
      const blockTypes = getItemsByCategory('blocks');
      const isBlock = blockTypes.includes(item.type);
      
      // Apply render distance limit
      const { x, y, z } = item.position;
      const distanceSquared = x*x + y*y + z*z;
      
      if (renderDistance <= 0) {
        // When render distance is 0, only show items at origin (exactly 0,0,0)
        if (distanceSquared <= 0.01) { // Small threshold
          visibleItems.add(id);
        }
      } else {
        // Normal render distance check
        const renderDistanceSquared = renderDistance * renderDistance;
        
        if (distanceSquared <= renderDistanceSquared) {
          // Create a common bounding box for frustum culling
          const blockSize = 1.0; // Standard block size
          const halfSize = blockSize / 2;
          const min = new THREE.Vector3(x - halfSize, y - halfSize, z - halfSize);
          const max = new THREE.Vector3(x + halfSize, y + halfSize, z + halfSize);
          const box = new THREE.Box3(min, max);
          
          // First check if the item is within the view frustum
          const isInFrustum = frustumRef.current.intersectsBox(box);
          
          if (!isInFrustum) {
            frustumCulledBlocksCount++;
            return; // Skip if not in frustum
          }
          
          // For non-blocks, we only need frustum culling
          if (!isBlock) {
            visibleItems.add(id);
          } else {
            // For blocks, check occlusion if enabled
            const roundedX = Math.round(x);
            const roundedY = Math.round(y);
            const roundedZ = Math.round(z);
            
            // Only apply occlusion culling if it's enabled in settings
            const isOccluded = enableOcclusionCulling && isBlockOccluded(roundedX, roundedY, roundedZ);
            
            if (!isOccluded) {
              visibleItems.add(id);
            } else {
              occludedBlocksCount++;
            }
          }
        }
      }
    });

    setVisibleItems(visibleItems);
    
    // Dispatch custom event with culling stats
    const occlusionEvent = new CustomEvent('occlusion-stats', { 
      detail: { 
        occludedBlocks: occludedBlocksCount,
        frustumCulledBlocks: frustumCulledBlocksCount,
        totalItems: totalProcessedItems
      } 
    });
    window.dispatchEvent(occlusionEvent);
  });

  // Get lighting settings based on mode
  const getLightingSettings = () => {
    switch (lightingMode) {
      case 'day':
        return {
          skyProps: { 
            distance: 450000, 
            sunPosition: [15, 30, 15] as [number, number, number], 
            inclination: 0.3, 
            azimuth: 0.25,
            rayleigh: 0.3
          },
          ambientLight: { intensity: 0.5, color: "#ffffff" },
          mainLight: { 
            position: [15, 30, 15] as [number, number, number], 
            intensity: 1.5, 
            color: "#fffaf0" 
          },
          fillLight: { 
            position: [-10, 15, -10] as [number, number, number], 
            intensity: 0.3, 
            color: "#b6ceff" 
          },
          environment: "park"
        };
      case 'sunset':
        return {
          skyProps: { 
            distance: 450000, 
            sunPosition: [15, 10, 15] as [number, number, number], 
            inclination: 0.6, 
            azimuth: 0.25,
            rayleigh: 0.5
          },
          ambientLight: { intensity: 0.3, color: "#fffaf0" },
          mainLight: { 
            position: [15, 10, 15] as [number, number, number], 
            intensity: 1.2, 
            color: "#ff9966" 
          },
          fillLight: { 
            position: [-10, 5, -10] as [number, number, number], 
            intensity: 0.4, 
            color: "#b6ceff" 
          },
          environment: "sunset"
        };
      case 'night':
        return {
          skyProps: { 
            distance: 450000, 
            sunPosition: [15, -5, 15] as [number, number, number], 
            inclination: 0.9, 
            azimuth: 0.25,
            rayleigh: 0.8
          },
          ambientLight: { intensity: 0.1, color: "#3a4a6a" },
          mainLight: { 
            position: [15, -5, 15] as [number, number, number], 
            intensity: 0.2, 
            color: "#3a4a6a" 
          },
          fillLight: { 
            position: [-10, 5, -10] as [number, number, number], 
            intensity: 0.1, 
            color: "#3a4a6a" 
          },
          environment: "night"
        };
      default:
        return {
          skyProps: { 
            distance: 450000, 
            sunPosition: [15, 20, 15] as [number, number, number], 
            inclination: 0.6, 
            azimuth: 0.25,
            rayleigh: 0.5
          },
          ambientLight: { intensity: 0.3, color: "#fffaf0" },
          mainLight: { 
            position: [15, 20, 15] as [number, number, number], 
            intensity: 1.2, 
            color: "#fffaf0" 
          },
          fillLight: { 
            position: [-10, 15, -10] as [number, number, number], 
            intensity: 0.4, 
            color: "#b6ceff" 
          },
          environment: "sunset"
        };
    }
  };

  const lighting = getLightingSettings();

  // Handler for background clicks to close menus
  const handleBackgroundClick = () => {
    // If a menu is open, close it when clicking on the background
    if (isMenuOpen) {
      closeMenu();
    }
    
    // Clear any active placement preview
    setPlacementPreview(null);
  };
  
  // Throttled placement preview update function
  const updatePlacementPreview = useCallback((e: MouseEvent | Touch) => {
    // Skip if menu is open or not in build mode
    if (isMenuOpen || !buildMode) {
      // Ensure preview is cleared if not in build mode
      if (!buildMode && lastPreviewState.current !== null) {
        lastPreviewState.current = null;
        setPlacementPreview(null);
      }
      return;
    }
    
    // Check if we should throttle this update
    const now = performance.now();
    if (now - lastUpdateTime.current < throttleTime) {
      // If we're throttling and no update is pending yet, schedule one
      if (!updatePending.current) {
        updatePending.current = true;
        
        if (animationFrameId.current !== null) {
          cancelAnimationFrame(animationFrameId.current);
        }
        
        animationFrameId.current = requestAnimationFrame(() => {
          // By the time this runs, we might have newer coordinates
          updatePlacementPreview(e);
          updatePending.current = false;
          animationFrameId.current = null;
        });
      }
      return;
    }
    
    // Update the timestamp
    lastUpdateTime.current = now;
    
    // Calculate mouse position in normalized device coordinates (-1 to +1)
    const canvas = gl.domElement;
    const rect = canvas.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((e.clientY - rect.top) / rect.height) * 2 + 1;
    
    // Update mouse position
    mouse.current.set(x, y);
    
    // Update raycaster with current mouse position and camera
    raycaster.setFromCamera(mouse.current, camera);
    
    // Collect valid hit objects to test against
    const hitObjects: THREE.Object3D[] = [];
    const blockTypes = getItemsByCategory('blocks');
    
    // Only test against non-block items that are actually visible
    Object.entries(meshRefs.current).forEach(([id, mesh]) => {
      if (mesh && items[id] && !blockTypes.includes(items[id].type)) {
        hitObjects.push(mesh);
      }
    });
    
    // If there are no non-block items to raycast against, return
    // (The InstancedBlocks component will handle block raycasting)
    if (hitObjects.length === 0) return;
    
    // Fast raycast against just the relevant objects
    const intersects = raycaster.intersectObjects(hitObjects, true);
    
    // Process intersections
    if (intersects.length > 0) {
      const intersection = intersects[0];
      
      // Try to find the parent Item component's ID
      let targetObj: THREE.Object3D | null = intersection.object;
      let targetId = null;
      
      // Find the parent object that has our ID
      while (targetObj && !targetId) {
        // See if this object or its parent has an ID in our items list
        for (const [id, mesh] of Object.entries(meshRefs.current)) {
          if (targetObj === mesh || (targetObj.parent && targetObj.parent === mesh)) {
            targetId = id;
            break;
          }
        }
        
        targetObj = targetObj.parent;
      }
      
      // If we found a valid target item
      if (targetId && items[targetId]) {
        const targetItem = items[targetId];
        const face = Math.floor(intersection.faceIndex! / 2);
        
        // Calculate directional offsets based on which face was hit
        const [dx, dy, dz] = [
          face === 0 ? 1 : face === 1 ? -1 : 0,
          face === 2 ? 1 : face === 3 ? -1 : 0,
          face === 4 ? 1 : face === 5 ? -1 : 0
        ];
        
        // Target and selected item properties
        const targetIsStackable = targetItem.stackable;
        const selectedIsStackable = isItemStackable(selectedItemType);
        const isValid = targetIsStackable || (!targetIsStackable && selectedIsStackable && face === 2);
        
        // Prepare new preview state
        let newPreviewState = null;
        
        // Create placement preview
        if (targetIsStackable) {
          // For stackable items, show preview on any face
          newPreviewState = {
            position: { 
              x: targetItem.position.x + dx, 
              y: targetItem.position.y + dy, 
              z: targetItem.position.z + dz 
            },
            type: selectedItemType,
            valid: isValid,
            targetItemId: targetId,
            placementFace: face,
            source: 'game' as const  // Mark this preview as coming from the Game component
          };
        } else if (face === 2) {
          // For non-stackable items, only allow placement on top
          newPreviewState = {
            position: { 
              x: targetItem.position.x, 
              y: targetItem.position.y + 1, 
              z: targetItem.position.z 
            },
            type: selectedItemType,
            valid: isValid,
            targetItemId: targetId,
            placementFace: face,
            source: 'game' as const  // Mark this preview as coming from the Game component
          };
        }
        
        // Only update if the preview has changed to avoid unnecessary re-renders
        if (newPreviewState && (!lastPreviewState.current || 
            JSON.stringify(newPreviewState) !== JSON.stringify(lastPreviewState.current))) {
          lastPreviewState.current = newPreviewState;
          setPlacementPreview(newPreviewState);
        }
        
        return;
      }
    }
    
    // If we've reached here, there's no valid intersection for non-block items
    // Only clear the preview if it was created by this component
    if (lastPreviewState.current !== null && placementPreview?.source === 'game') {
      lastPreviewState.current = null;
      setPlacementPreview(null);
    }
  }, [isMenuOpen, selectedItemType, items, setPlacementPreview, camera, gl.domElement, buildMode, raycaster, placementPreview]);
  
  // Mouse down handler
  const handleMouseDown = useCallback((e: MouseEvent) => {
    mouseDownRef.current = true;
    // Store initial position for movement detection
    pointerDownPosition.current = { x: e.clientX, y: e.clientY };
    hasMovedSignificantly.current = false;
  }, []);
  
  // Mouse up handler
  const handleMouseUp = useCallback((e: MouseEvent) => {
    // Check if mouse has moved significantly (camera movement)
    const hasMoved = checkSignificantMovement(e.clientX, e.clientY);
    
    // Only handle click interaction if the pointer hasn't moved much and we have a valid preview
    if (!hasMoved && buildMode && selectedItemType && placementPreview && placementPreview.valid) {
      // If the preview is from this component or we have no source information
      if (!placementPreview.source || placementPreview.source === 'game') {
        // Get the position from the preview
        const { x, y, z } = placementPreview.position;
        
        try {
          // Add the new item
          console.log("Game: Adding item at position:", x, y, z, "type:", selectedItemType);
          addItem(x, y, z, selectedItemType);
        } catch (err) {
          console.error("Error adding item:", err);
        }
      }
    }
    
    // Reset tracking
    mouseDownRef.current = false;
    pointerDownPosition.current = null;
  }, [buildMode, selectedItemType, placementPreview, addItem, checkSignificantMovement]);
  
  // Mouse event handlers
  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      // Prevent right-click context menu
      e.preventDefault();
    };
    
    // Add event listeners
    gl.domElement.addEventListener('contextmenu', handleContextMenu);
    
    return () => {
      // Remove event listeners
      gl.domElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [gl.domElement]);
  
  // Set up mouse handlers for placement preview
  useEffect(() => {
    const mouseMoveHandler = (e: MouseEvent) => {
      // Only handle non-block items in Game component
      // InstancedBlocks handles blocks
      updatePlacementPreview(e);
    };
    
    const touchStartHandler = (e: TouchEvent) => {
      if (e.touches.length > 0) {
        const touchEvent = e.touches[0];
        // Store initial position for movement detection
        pointerDownPosition.current = { x: touchEvent.clientX, y: touchEvent.clientY };
        hasMovedSignificantly.current = false;
      }
    };
    
    const touchMoveHandler = (e: TouchEvent) => {
      // Handle touch move for placement preview
      if (e.touches.length > 0) {
        const touchEvent = e.touches[0];
        updatePlacementPreview(touchEvent);
        
        // Check if we've moved significantly
        checkSignificantMovement(touchEvent.clientX, touchEvent.clientY);
      }
    };
    
    const touchEndHandler = (e: TouchEvent) => {
      if (e.changedTouches.length > 0) {
        // Only handle touch if it hasn't moved significantly
        if (!hasMovedSignificantly.current && buildMode && selectedItemType && placementPreview && placementPreview.valid) {
          // If the preview is from this component or we have no source information
          if (!placementPreview.source || placementPreview.source === 'game') {
            // Get the position from the preview
            const { x, y, z } = placementPreview.position;
            
            try {
              // Add the new item
              console.log("Game: Adding item from touch at position:", x, y, z, "type:", selectedItemType);
              addItem(x, y, z, selectedItemType);
            } catch (err) {
              console.error("Error adding item from touch:", err);
            }
          }
        }
        
        // Reset tracking
        pointerDownPosition.current = null;
      }
    };
    
    // Add event listeners
    gl.domElement.addEventListener('mousemove', mouseMoveHandler, { passive: true });
    gl.domElement.addEventListener('touchstart', touchStartHandler, { passive: true });
    gl.domElement.addEventListener('touchmove', touchMoveHandler, { passive: true });
    gl.domElement.addEventListener('touchend', touchEndHandler);
    gl.domElement.addEventListener('mousedown', handleMouseDown);
    gl.domElement.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      // Remove event listeners
      gl.domElement.removeEventListener('mousemove', mouseMoveHandler);
      gl.domElement.removeEventListener('touchstart', touchStartHandler);
      gl.domElement.removeEventListener('touchmove', touchMoveHandler);
      gl.domElement.removeEventListener('touchend', touchEndHandler);
      gl.domElement.removeEventListener('mousedown', handleMouseDown);
      gl.domElement.removeEventListener('mouseup', handleMouseUp);
    };
  }, [gl.domElement, updatePlacementPreview, handleMouseDown, handleMouseUp, checkSignificantMovement, buildMode, selectedItemType, placementPreview, addItem]);

  // Toggle debug visualization with key press
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'F3') {
        setShowCullingDebug(prev => !prev);
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  // Debugging helper to visualize frustum culling
  const renderDebugFrustum = () => {
    if (!showCullingDebug) return null;
    
    // Get the current list of visible items from the store for comparison
    const currentVisibleItems = useGameStore.getState().visibleItems;
    
    return (
      <group>
        {/* Create a wireframe cone to represent the camera frustum */}
        <mesh position={[0, 0, 0]}>
          <coneGeometry args={[5, 10, 4, 1, true]} />
          <meshBasicMaterial wireframe color="yellow" />
        </mesh>
        
        {/* Show which blocks are in frustum vs culled */}
        {Object.entries(items).map(([id, item]) => {
          const isVisible = currentVisibleItems.has(id);
          const { x, y, z } = item.position;
          
          // Skip items in visibleItems set - they're already being rendered
          if (isVisible) return null;
          
          // Render a wireframe for culled items
          return (
            <mesh key={`debug-${id}`} position={[x, y, z]}>
              <boxGeometry args={[1, 1, 1]} />
              <meshBasicMaterial wireframe color="red" transparent opacity={0.3} />
            </mesh>
          );
        })}
      </group>
    );
  };

  return (
    <>
      {/* Placeholder for origin that can be used for positioning */}
      <mesh visible={false} position={[0, 0, 0]} />
      
      {/* Lighting for time of day */}
      <directionalLight 
        position={lightingMode === 'night' 
          ? [0, 10, 0] 
          : lightingMode === 'sunset' 
            ? [10, 3, 10] 
            : [10, 10, 10]
        } 
        intensity={lightingMode === 'night' ? 0.1 : 1} 
        castShadow={showShadows}
        shadow-mapSize={memoryOptimization ? [512, 512] : [1024, 1024]}
        shadow-camera-far={memoryOptimization ? 50 : 100}
        shadow-camera-near={0.1}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
        />
      
      {/* Add ambient light so shadows aren't too dark */}
      <ambientLight intensity={lightingMode === 'night' ? 0.5 : 0.7} />
      
      {/* Environment with appropriate sky based on time of day */}
      {lightingMode !== 'night' && (
        <Sky
          distance={300}
          sunPosition={
            lightingMode === 'sunset' 
              ? [10, 3, 10] 
              : [10, 10, 10]
          }
          rayleigh={lightingMode === 'sunset' ? 6 : 0.5}
          turbidity={lightingMode === 'sunset' ? 10 : 8}
          mieCoefficient={0.005}
          mieDirectionalG={0.7}
        />
      )}
      
      {/* Only use SoftShadows if memory optimization is off */}
      {showShadows && !memoryOptimization && <SoftShadows />}
      
      {/* Add contact shadows based on memory settings */}
      {showShadows && (
        <ContactShadows 
          position={[0, -0.01, 0]} 
          opacity={0.6} 
          scale={200} 
          blur={1} 
          far={10} 
          resolution={memoryOptimization ? 128 : 256} 
        />
      )}
      
      {/* Invisible background plane to catch clicks */}
      <mesh 
        position={[0, -0.5, 0]} 
        rotation={[-Math.PI / 2, 0, 0]} 
        onClick={handleBackgroundClick}
      >
        <planeGeometry args={[1000, 1000]} />
        <meshBasicMaterial visible={false} />
      </mesh>
      
      {/* Secondary fill light */}
      <directionalLight 
        position={lighting.fillLight.position} 
        intensity={lighting.fillLight.intensity} 
        color={lighting.fillLight.color}
      />
      
      {/* Placement preview for showing where items will be placed */}
      <PlacementPreview />
      
      {/* Use instanced rendering for blocks */}
      <InstancedBlocks />
      
      {/* Render non-block items */}
      {Object.entries(items).map(([id, item]) => {
        // Skip block items since they're handled by InstancedBlocks
        if (getItemsByCategory('blocks').includes(item.type)) {
          return null;
        }
        
        // Skip items outside render distance
        const distanceSquared = 
          item.position.x * item.position.x + 
          item.position.y * item.position.y + 
          item.position.z * item.position.z;
        
        // When render distance is 0, don't render any items except at origin
        if (renderDistance <= 0) {
          // Only render items at origin (exactly 0,0,0) when render distance is 0
          if (distanceSquared > 0.01) { // Small threshold to catch exact origins
            return null;
          }
        } else {
          // Normal render distance check
          const renderDistanceSquared = renderDistance * renderDistance;
          if (distanceSquared > renderDistanceSquared) {
            return null;
          }
        }
        
        // Adjust position and convert to array for R3F
        const renderPosition: [number, number, number] = [item.position.x, item.position.y, item.position.z];
        
        return (
          <Item
            key={id}
            id={id}
            position={renderPosition}
            type={item.type}
            rotation={item.rotation}
            scale={item.scale}
            stackable={item.stackable}
            rotatable={item.rotatable}
            castShadow={showShadows}
            receiveShadow={showShadows}
            ref={(mesh: THREE.Object3D) => {
              if (mesh) {
                meshRefs.current[id] = mesh;
              } else {
                delete meshRefs.current[id];
              }
            }}
          />
        );
      })}
      
      {/* Debug visualization */}
      {renderDebugFrustum()}
    </>
  );
};

export default Game; 