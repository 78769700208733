import React, { useState, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Grid } from '@react-three/drei';
import Game from './components/game/Game';
import UI from './components/ui/UI';
import WelcomeScreen from './components/ui/WelcomeScreen';
import { MenuManager } from './components/game/Item';
import './App.css';
import * as THREE from 'three';

// Create a global event bus for camera adjustments
export const adjustCamera = (worldSize: number) => {
  const event = new CustomEvent('adjust-camera', { detail: { worldSize } });
  window.dispatchEvent(event);
};

const App: React.FC = () => {
  const [showWelcome, setShowWelcome] = useState<boolean>(true);
  const controlsRef = useRef<any>(null);
  
  // Handle closing the welcome screen
  const handleCloseWelcome = () => {
    setShowWelcome(false);
  };
  
  // Listen for camera adjustment events
  useEffect(() => {
    const handleAdjustCamera = (event: CustomEvent) => {
      if (controlsRef.current && event.detail && typeof event.detail.worldSize === 'number') {
        const worldSize = event.detail.worldSize;
        
        // Calculate optimal camera position based on world size
        const distance = Math.max(20, worldSize * 1.5); // Scale distance with world size
        const height = Math.max(15, worldSize * 0.5); // Scale height with world size
        
        // Set target to center of world
        controlsRef.current.target.set(0, 0, 0);
        
        // Set camera position
        controlsRef.current.object.position.set(distance, height, distance);
        
        // Update the controls
        controlsRef.current.update();
      }
    };
    
    // Add event listener
    window.addEventListener('adjust-camera', handleAdjustCamera as EventListener);
    
    return () => {
      // Remove event listener on cleanup
      window.removeEventListener('adjust-camera', handleAdjustCamera as EventListener);
    };
  }, []);
  
  return (
    <MenuManager>
      <div className="app">
        <Canvas
          shadows="soft"
          camera={{ position: [10, 10, 10], fov: 50 }}
          onContextMenu={(e) => e.preventDefault()}
          onCreated={({ gl }) => {
            gl.setPixelRatio(window.devicePixelRatio);
            // @ts-ignore - physicallyCorrectLights is valid but TypeScript doesn't recognize it
            gl.physicallyCorrectLights = true;
          }}
          // Use simple "always" mode for reliable rendering
          frameloop="always"
          gl={{ 
            powerPreference: 'high-performance',
            antialias: window.devicePixelRatio > 1 ? false : true, // Only use antialiasing on low-DPI screens
            stencil: false,
            depth: true,
            alpha: true
          }}
          style={{ background: 'radial-gradient(circle, #2b4c7d 0%, #1a2535 100%)' }}
        >
          <OrbitControls
            ref={controlsRef}
            enableDamping
            dampingFactor={0.25}
            rotateSpeed={0.4}
            minDistance={5}
            maxDistance={200} // Increased for larger worlds
            maxPolarAngle={Math.PI / 2.5}
            enablePan={true}
            panSpeed={0.5}
            screenSpacePanning={true}
            touches={{ ONE: THREE.TOUCH.ROTATE, TWO: THREE.TOUCH.DOLLY_PAN }}
          />
          <Grid
            infiniteGrid
            cellSize={1}
            sectionSize={10}
            fadeStrength={1.5}
            fadeDistance={50}
            position={[0, -0.01, 0]}
          />
          <Game />
        </Canvas>
        <UI />
        
        {showWelcome && <WelcomeScreen onClose={handleCloseWelcome} />}
      </div>
    </MenuManager>
  );
};

export default App;
