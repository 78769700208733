import React from 'react';

const SimpleHut: React.FC = () => {
  // Colors
  const woodColor = "#8B4513"; // SaddleBrown
  const roofColor = "#A0522D"; // Sienna
  const thatchColor = "#D2B48C"; // Tan

  return (
    <group position={[0, -0.2, 0]}>
      {/* Elevated platform/foundation */}
      <mesh position={[0, 0.1, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.8, 0.2, 1.8]} />
        <meshStandardMaterial color={woodColor} roughness={0.9} />
      </mesh>

      {/* Floor with wood planks appearance */}
      <mesh position={[0, 0.25, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.6, 0.1, 1.6]} />
        <meshStandardMaterial color={woodColor} roughness={0.8} />
      </mesh>

      {/* Main structure - walls */}
      <mesh position={[0, 0.8, 0]} castShadow receiveShadow>
        <boxGeometry args={[1.5, 1, 1.5]} />
        <meshStandardMaterial color={woodColor} roughness={0.8} />
      </mesh>

      {/* Corner posts */}
      {[
        [0.75, 0.7, 0.75] as [number, number, number],
        [0.75, 0.7, -0.75] as [number, number, number],
        [-0.75, 0.7, 0.75] as [number, number, number],
        [-0.75, 0.7, -0.75] as [number, number, number]
      ].map((pos, i) => (
        <mesh key={`post-${i}`} position={pos} castShadow receiveShadow>
          <boxGeometry args={[0.15, 1.2, 0.15]} />
          <meshStandardMaterial color={woodColor} roughness={0.9} />
        </mesh>
      ))}

      {/* Conical thatched roof */}
      <mesh position={[0, 1.5, 0]} castShadow receiveShadow>
        <coneGeometry args={[1.2, 0.8, 8]} />
        <meshStandardMaterial color={thatchColor} roughness={1} />
      </mesh>

      {/* Roof peak */}
      <mesh position={[0, 2, 0]} castShadow receiveShadow>
        <coneGeometry args={[0.2, 0.3, 8]} />
        <meshStandardMaterial color={roofColor} roughness={0.8} />
      </mesh>

      {/* Door */}
      <mesh position={[0, 0.8, 0.76]} castShadow receiveShadow>
        <boxGeometry args={[0.5, 0.9, 0.05]} />
        <meshStandardMaterial color={woodColor} roughness={0.9} />
      </mesh>

      {/* Door handle */}
      <mesh position={[0.15, 0.8, 0.79]} castShadow receiveShadow>
        <boxGeometry args={[0.05, 0.05, 0.05]} />
        <meshStandardMaterial color="#A1887F" metalness={0.5} roughness={0.5} />
      </mesh>

      {/* Windows - with frames */}
      {[0, Math.PI].map((rotation, i) => (
        <group key={`window-${i}`} position={[0, 0.9, i === 0 ? -0.76 : 0]} rotation={[0, rotation, 0]}>
          <mesh castShadow receiveShadow>
            <boxGeometry args={[0.4, 0.4, 0.05]} />
            <meshStandardMaterial color="#BBDEFB" transparent opacity={0.7} />
          </mesh>
          
          {/* Window frame */}
          <mesh position={[0, 0, 0.01]} castShadow receiveShadow>
            <boxGeometry args={[0.45, 0.05, 0.07]} />
            <meshStandardMaterial color={woodColor} />
          </mesh>
          <mesh position={[0, 0, 0.01]} castShadow receiveShadow>
            <boxGeometry args={[0.05, 0.45, 0.07]} />
            <meshStandardMaterial color={woodColor} />
          </mesh>
        </group>
      ))}

      {/* Side windows */}
      {[-0.76, 0.76].map((x, i) => (
        <group key={`side-window-${i}`} position={[x, 0.9, 0]} rotation={[0, Math.PI/2, 0]}>
          <mesh castShadow receiveShadow>
            <boxGeometry args={[0.4, 0.4, 0.05]} />
            <meshStandardMaterial color="#BBDEFB" transparent opacity={0.7} />
          </mesh>
          
          {/* Window frame */}
          <mesh position={[0, 0, 0.01]} castShadow receiveShadow>
            <boxGeometry args={[0.45, 0.05, 0.07]} />
            <meshStandardMaterial color={woodColor} />
          </mesh>
          <mesh position={[0, 0, 0.01]} castShadow receiveShadow>
            <boxGeometry args={[0.05, 0.45, 0.07]} />
            <meshStandardMaterial color={woodColor} />
          </mesh>
        </group>
      ))}
    </group>
  );
};

export default SimpleHut; 